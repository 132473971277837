import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/revenuegoals.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useAppSelector } from "../../hooks/hooks";
const RevenueGoals = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>
          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              How to Reach Your Revenue Goals
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Almost every business sets revenue goals for the year. Most will
              check their progress towards those goals by looking at how much
              revenue they’ve brought in to date and then comparing to previous
              years. But what a lot of businesses don’t do or don’t know how to
              do is look at why their revenue is trending up or down. That’s a
              problem because, unless you understand the “why”, you won’t know
              what to do to improve it.
            </Typography>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Which business metrics matter
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              In order to understand your progress toward revenue goals, two of
              the most important business metrics or analytics to look at are
              customer retention and purchase frequency. Customer retention
              measures the percentage of customers that purchased within the
              last 12 months that also purchased within the prior 12 months.
              Purchase frequency tracks the number of times a customer buys
              products or services from a company within a given period.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Customer retention
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Customer retention is key to reaching your revenue goals, because
              your customers spend more every year that they do business with
              you: 30% more in year two and another 50% more in year three. The
              longer you retain your customers, the greater their spend will be
              and the higher your revenue will be. In fact, increasing customer
              retention by just 5% can boost profits by 25% to 95%.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Purchase frequency
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Purchase frequency is just as important as customer retention. If
              a customer’s purchase frequency is decreasing, then you are at
              risk of losing them. If their purchase frequency is remaining
              steady or increasing, then you have a better chance of retaining
              them over the long term. A higher purchase frequency can also mean
              a higher customer spend, moving you closer towards your revenue
              goals.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Improve customer retention and purchase frequency to reach your
              revenue goals
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Increasing retention and purchase frequency is crucial for
              boosting the amount of money you bring in. Winsby has tried and
              proven methods for expanding both.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Customer retention
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              The simplest method for improving retention is to find out why
              customers are leaving. The best way to do that is through customer
              satisfaction surveys conducted by an outside third party like
              Winsby. Using a third party will elicit candid comments that
              customers may not want to share with an employee of your company,
              and the surveys will provide specific feedback, so you can solve
              any issues quickly.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              We see an increase in retention rates of 20% or more when
              customers are routinely surveyed about their experience with a
              company in Winsby’s customer satisfaction surveys.
            </Typography>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              Purchase frequency
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Distributing emails to customers and prospects increases how often
              customers purchase from you and remind customers of everything you
              offer. In fact, with Winsby’s emails, customers on your
              distribution list will generally purchase two to three times more
              often than customers who are not receiving the emails.
            </Typography>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              Understanding your business metrics
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Before you can improve key business metrics like retention,
              purchase frequency, and others, you have to start measuring
              analytics, know how they trend, and understand what those trends
              mean for your company. When you work with Zintoro and Winsby, you
              have a portal where you can view all the most important business
              metrics. Our financial experts will walk you through them, provide
              insights on trends, and help you make better decisions for your
              company.
            </Typography>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              Business metrics available through your portal:
            </Typography>
            <List sx={{ listStyleType: "disc", padding: "0px 0px 0px 20px" }}>
              <ListItem sx={{ display: "list-item" }}>
                Historical customer retention rate
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Net growth rate for number of accounts
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                The number of active accounts
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                How often customers purchase
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>Top customers</ListItem>
              <ListItem sx={{ display: "list-item" }}>
                When customers purchased last & who hasn’t purchased recently
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>New customers</ListItem>
              <ListItem sx={{ display: "list-item" }}>Lost customers</ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Historical revenue
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Growth in the number of invoices
              </ListItem>
            </List>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              If you want to start measuring analytics, stay on track for your
              2023 revenue goals, and set achievable revenue goals for 2024,{" "}
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/contactus"}
              >
                reach out to our team today!
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RevenueGoals;
