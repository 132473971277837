import {
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import our_partners from "./../../assets/banner-whereyouare.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contactUsInfo } from "../../types/formTypes";
import { useAppSelector } from "../../hooks/hooks";

const WhereYouAre = () => {
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  let contactUsInitialValues: contactUsInfo = {
    full_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    questions: "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validateContactUs = Yup.object({
    full_name: Yup.string().required("Fist Name is Required"),
    company_name: Yup.string().required("Last name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number isRequired"),
    questions: Yup.string().required("Question is Required"),
  });

  const contactUsForm = useFormik({
    initialValues: contactUsInitialValues,
    validationSchema: validateContactUs,
    onSubmit: (values: contactUsInfo) => {
      console.log("contactUsForm values", values);
    },
  });
  const navigate = useNavigate();
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_partners})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "2% 10% 5% 10%" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Where You Are?
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} sx={{}}>
          <Typography
            variant="h5"
            sx={{
              color: "#3aae4a",
              fontWeight: "bold",
              margin: "20px 0px 0px 0px ",
            }}
          >
            Is your business reaching its potential?
          </Typography>
          <Typography sx={{ margin: "10px 0px 0px 0px " }}>
            To determine where you are now, you will provide the past three
            years of invoices for your company. Zintoro will load the data into
            our programs that have been developed through artificial
            intelligence to provide an assessment of opportunities for your
            business.
          </Typography>
          <Typography sx={{ margin: "10px 0px 0px 0px " }}>
            The Zintoro program shows very clearly the patterns for your
            business during the time represented by the data submitted and
            identifies changes that are necessary in order to reverse existing
            patterns.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#3aae4a",
              fontWeight: "bold",
              margin: "20px 0px 0px 0px",
            }}
          >
            Find out where to focus for growth
          </Typography>
          <Typography sx={{ margin: "10px 0px 0px 0px " }}>
            The analysis focuses on how existing resources can be leveraged for
            sustainable and predictable growth. Guidance on what additional
            steps and tools are the best matches to meet your plans and goals
            most effectively and efficiently.
          </Typography>
          <Typography sx={{ margin: "10px 0px 0px 0px " }}>
            From staff productivity to customer engagement, our assessment of
            where you are now is like an x-ray for your business, showing
            clearly what’s strong and what needs more support.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#3aae4a",
              fontWeight: "bold",
              margin: "20px 0px 0px 0px",
            }}
          >
            Components of the initial assessment
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            The initial assessment showing where you are now includes:
          </Typography>
          <List sx={{ padding: "10px 0px 0px 20px", listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              Core key metrics reports include :
            </ListItem>
            <Typography sx={{ padding: "0px 0px 0px 20px" }}>
              Customers at risk of being lost
            </Typography>
            <Typography sx={{ padding: "0px 0px 0px 20px" }}>
              Your Zintoro Growth and Stability Score
            </Typography>
            <Typography sx={{ padding: "0px 0px 0px 20px" }}>
              Customer Retention Sales Leads
            </Typography>
            <ListItem sx={{ display: "list-item", margin: "20px 0px 0px 0px" }}>
              Market industry analysis that identifies potential business in
              your market, including geographic and industry constraints
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Website and online performance assessment to evaluate your online
              image, including:
            </ListItem>
            <Typography sx={{ padding: "0px 0px 0px 20px" }}>
              Your reputation among your customers
            </Typography>
            <Typography sx={{ padding: "0px 0px 0px 20px" }}>
              Your reputation among your employees
            </Typography>
            <ListItem sx={{ display: "list-item", margin: "20px 0px 0px 0px" }}>
              Conference call with an in-depth review of your business
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Recommendations to improve growth and performance
            </ListItem>
          </List>
        </Grid>
        <Grid container item xs={4} sx={{ padding: "20px" }}>
          <Grid item xs={12} sx={{}}>
            <Paper elevation={3}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#00b93f",
                  height: "150px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", color: "#ccc" }}
                >
                  Ask a Question
                </Typography>
                <Typography variant="h4" sx={{ color: "white" }}>
                  &#42;Required
                </Typography>
              </Grid>
              <form onSubmit={contactUsForm.handleSubmit}>
                <Grid container gap={2} sx={{ padding: "20px" }}>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Full Name *"
                      label="Full Name *"
                      id="full_name"
                      name="full_name"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={contactUsForm.values.full_name}
                      error={
                        contactUsForm.touched.full_name &&
                        Boolean(contactUsForm.errors.full_name)
                      }
                      helperText={contactUsForm.errors.full_name}
                      onChange={contactUsForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Email *"
                      label="Email *"
                      id="email"
                      name="email"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={contactUsForm.values.email}
                      error={
                        contactUsForm.touched.email &&
                        Boolean(contactUsForm.errors.email)
                      }
                      helperText={contactUsForm.errors.email}
                      onChange={contactUsForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Company Name *"
                      label="Company Name *"
                      id="company_name"
                      name="company_name"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={contactUsForm.values.company_name}
                      error={
                        contactUsForm.touched.company_name &&
                        Boolean(contactUsForm.errors.company_name)
                      }
                      helperText={contactUsForm.errors.company_name}
                      onChange={contactUsForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Phone Number *"
                      label="Phone Number *"
                      id="phone_number"
                      name="phone_number"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={contactUsForm.values.phone_number}
                      error={
                        contactUsForm.touched.phone_number &&
                        Boolean(contactUsForm.errors.phone_number)
                      }
                      helperText={contactUsForm.errors.phone_number}
                      onChange={contactUsForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextareaAutosize
                      placeholder="Questions *"
                      id="questions"
                      name="questions"
                      maxRows={4}
                      minRows={4}
                      style={{ width: "100%" }}
                      value={contactUsForm.values.questions}
                      onChange={contactUsForm.handleChange}
                    />
                    {contactUsForm.touched.questions &&
                      Boolean(contactUsForm.errors.questions) && (
                        <Typography sx={{ color: "red" }}>
                          {contactUsForm.errors.questions}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <Button
                      variant="contained"
                      sx={{ width: "100%", backgroundColor: "#56baed" }}
                      onClick={() => contactUsForm.submitForm}
                      type="submit"
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          padding: "0% 10% 5% 10%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{ height: "75px", width: "300px" }}
          onClick={() => {
            navigate("/contactus");
          }}
        >
          Schedule a Demo
        </Button>
      </Grid>
    </Grid>
  );
};

export default WhereYouAre;
