import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/revenue.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useAppSelector } from "../../hooks/hooks";
const RevenueAnalyticsGoals = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>

          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              Achieve Your Revenue Goals: The First Step Is Understanding Where
              You Are Now
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Revenue and transaction trends identify your company’s sales
              patterns over the prior twelve month period. Recognizing these
              patterns and what activities lead to growth is a critical step in
              making the right decisions for your company. Understanding your
              company’s revenue analytics and trends will help you answer the
              following key questions
            </Typography>
            <List sx={{ listStyleType: "disc", padding: "10px 0px 0px 10px" }}>
              <ListItem sx={{ display: "list-item" }}>
                Who should you target for marketing initiatives?
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Which products or services should you focus on?
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                What customer and prospects should your salespeople spend time
                contacting?
              </ListItem>
            </List>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              How to meet your revenue goals
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Trends for transactions and customer retention are the primary
              drivers of revenue, because they explain exactly what is happening
              in your business. They also tell you where your sales team and
              marketing efforts should focus to engage your customers and keep
              them coming back consistently.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Here’s an example. For one company, new customers during the past
              12 months make up 51% of their customers. Here’s how these
              customers evolve over time, if they keep buying from the company:
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Average transactions
            </Typography>
            <Typography sx={{ width: "100%" }}>Year 1: 3</Typography>
            <Typography sx={{ width: "100%" }}>Year 2: 13</Typography>
            <Typography sx={{ width: "100%" }}>Year 3: 18</Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Average revenue
            </Typography>
            <Typography sx={{ width: "100%" }}>Year 1: $39,099</Typography>
            <Typography sx={{ width: "100%" }}>Year 2: $154,537</Typography>
            <Typography sx={{ width: "100%" }}>Year 3: $178,789</Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              What these revenue analytics show is that customers purchase more
              from you and are more valuable to you the longer they work with
              you. For this reason, retaining new customers over the long term
              has a huge impact on meeting your revenue goals.
            </Typography>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px " }}
            >
              Take advantage of revenue trends
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              For most companies, each year they keep a customer buying from
              them, that customer increases the number of purchases and how much
              they spend. One of the most critical aspects of reaching your
              revenue goals consistently is to ensure customer retention. Below
              are some strategies for retaining your customers:
            </Typography>
            <List sx={{ listStyleType: "disc", padding: "10px 0px 0px 10px" }}>
              <ListItem sx={{ display: "list-item" }}>
                <span style={{ fontWeight: "bold" }}>
                  Consistent engagement
                </span>{" "}
                – Send several targeted emails every month to your customers,
                reminding them of the products and services you offer,
                establishing yourself as an expert in your field, and
                encouraging them to purchase.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <span style={{ fontWeight: "bold" }}>Targeted approach</span> –
                Look at your customer purchase data to anticipate what they may
                need or when they might be getting low on a product. Then send
                out an email or make a sales call that is specific to that
                customer’s needs.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <span style={{ fontWeight: "bold" }}>
                  Collect and respond to feedback
                </span>{" "}
                – Implement customer satisfaction surveys to gain insights into
                how customers feel about your company and how well you provide
                for their needs. You’ll learn about issues and problems and have
                the chance to solve them before a customer leaves you. Effective
                customer satisfaction surveys can boost retention by 20% – 30%.
              </ListItem>
            </List>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Stay on top of your customers with emails, sales calls, and
              surveys to ensure they continue working with you and that you
              reach and exceed your revenue goals.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px " }}
            >
              Understand your revenue analytics with Zintoro
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              By knowing which customer segments and which products produce the
              most value for your company, you can better target your sales team
              and marketing efforts. Focus on retaining your current customers,
              and highlight the products that generate the most revenue. That
              means that your sales people should be reaching out to at risk
              customers, encouraging them to stay, and your marketing people
              should be putting the most resources behind your frequently bought
              products and services. Zintoro’s analytics portal shows you
              exactly which customers should be called when and which products
              should be promoted.
            </Typography>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              If you want to understand your revenue analytics and implement
              tried and proven strategies to exceed your revenue goals,{" "}
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/contactus"}
              >
                Contact Zintoro for a market analysis today!
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RevenueAnalyticsGoals;
