import { createSlice } from "@reduxjs/toolkit";
import { getEquipmentAnalytic } from "../../thunks/portalManagementThunk";
import { selectInfo } from "../../../types/formTypes";

interface EquipmentManagementState {
  equipmentAnalyticLoading: Boolean;

  error: any;
  response: any;
  success: Boolean;

  equipmentAnalyticRawData?: any;
  allEquipmentTransaction?: any;
  equipmentRegion?: any;
  equipmentSummary?: any;
  equipmentTransaction?: any;
  equipmentMachines?: any;
  retailChain?: any;
}

const initialState: EquipmentManagementState = {
  equipmentAnalyticLoading: false,

  error: null,
  response: null,
  success: false,

  equipmentAnalyticRawData: null,
  allEquipmentTransaction: null,
  equipmentRegion: null,
  equipmentSummary: null,
  equipmentTransaction: null,
  equipmentMachines: null,
  retailChain: null,
};

export const EquipmentManagementSlice = createSlice({
  name: "equipmentManagement",
  initialState,
  reducers: {
    equipmentManagementReset: (state) => {
      state.equipmentAnalyticRawData = null;

      state.equipmentAnalyticLoading = false;
    },
    equipmentManagementSearchReset: (state) => {
      state.equipmentAnalyticRawData = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getEquipmentAnalytic.pending, (state) => {
      state.equipmentAnalyticLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentAnalytic.fulfilled, (state, action) => {
      state.equipmentAnalyticLoading = false;
      // state.allEquipmentTransaction = action.payload.equip_all_transaction_dic;
      // state.equipmentRegion = action.payload.equip_region;
      // state.equipmentSummary = action.payload.equip_summary_dic;
      // state.equipmentTransaction = action.payload.equip_transaction_dic;
      // state.equipmentMachines = action.payload.machine;
      // state.retailChain = action.payload.retail_chain;
      // state.equipmentAnalyticRawData = action.payload;
    });
    builder.addCase(getEquipmentAnalytic.rejected, (state, action) => {
      state.equipmentAnalyticLoading = false;
      state.error = action.payload;
    });
  },
});

export const {
  equipmentManagementReset,

  equipmentManagementSearchReset,
} = EquipmentManagementSlice.actions;

export const equipmentManagementReducer = EquipmentManagementSlice.reducer;
