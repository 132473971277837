import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ClientInfo } from "../../../../types/formTypes";
import { editClient } from "../../../../store/thunks/systemManagementThunk";
import { compareTwoObjectArray } from "../../../../utils/utils";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface EditClientDialogProps {
  open: any;
  handleEditClientClose: any;
  editClientData?: any;
}

const EditClientDialog = (props: EditClientDialogProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const dispatch = useAppDispatch();
  const { open, handleEditClientClose, editClientData } = props;

  const { allPrograms, addClientLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const [enableSubmitEditClient, setEnableSubmitEditClient] = useState(true);

  const enableSubmit = () => {
    if (editClientData) {
      if (
        editClientForm.values?.client_name === editClientData?.customer_name &&
        editClientForm.values?.client_id === editClientData?.customer_id &&
        dayjs(editClientForm.values?.create_date).format("DD-MM-YYYY") ===
          dayjs(editClientData?.create_date).format("DD-MM-YYYY") &&
        compareTwoObjectArray(
          editClientData?.programs,
          editClientForm.values.programs,
          "program_index"
        )
      ) {
        setEnableSubmitEditClient(true);
      } else {
        setEnableSubmitEditClient(false);
      }
    } else {
      setEnableSubmitEditClient(false);
    }
  };

  const handleCloseEditClientDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setEditClientError(false);
    setEditClientErrorMessage("");
    setProgramErrorEditClient(false);
    setProgramErrorMessageEditClient("");
    handleEditClientClose(message);
  };

  let editClientInitialValues: ClientInfo = {
    client_id: editClientData?.customer_id,
    client_name: editClientData?.customer_name,
    programs: editClientData?.programs ? editClientData?.programs : [],
    create_date: editClientData?.create_date,
  };

  const [editClientError, setEditClientError] = useState(false);
  const [editClientErrorMessage, setEditClientErrorMessage] = useState("");

  const [programErrorEditClient, setProgramErrorEditClient] = useState(false);
  const [programErrorMessageEditClient, setProgramErrorMessageEditClient] =
    useState("");

  const validateEditClient = Yup.object({
    client_id: Yup.number().required("Client id is Required"),
    client_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Client Name is Required"),
    programs: Yup.mixed(),
  });

  const editClientForm = useFormik({
    initialValues: editClientInitialValues,
    validationSchema: validateEditClient,
    enableReinitialize: true,
    onSubmit: (values: ClientInfo) => {
      setProgramErrorEditClient(false);
      setProgramErrorMessageEditClient("");
      setEditClientErrorMessage("");
      setEditClientError(false);
      if (values.programs) {
        setProgramErrorEditClient(false);
        setProgramErrorMessageEditClient("");
      } else {
        setProgramErrorEditClient(true);
        setProgramErrorMessageEditClient("programs is required");
        return;
      }

      if (
        values.client_id === editClientData?.customer_id &&
        values.client_name === editClientData?.customer_name &&
        compareTwoObjectArray(
          values.programs,
          editClientData.programs,
          "program_index"
        ) &&
        dayjs(editClientForm.values?.create_date).format("DD-MM-YYYY") ===
          dayjs(editClientData?.create_date).format("DD-MM-YYYY")
      ) {
        setEditClientErrorMessage("No Changes have been made");
        setEditClientError(true);
        return;
      } else {
        setEditClientErrorMessage("");
        setEditClientError(false);
      }
      const editClientPayload = {
        client_index: editClientData?.client_index,
        current_update_programs: editClientData.programs.map(
          (program: any) => program?.program_index
        ),

        new_customer_id: values.client_id,
        new_client_name: values.client_name,
        new_client_program_index: values.programs.map(
          (program: any) => program?.program_index
        ),
        new_create_date: values.create_date.format("MM-DD-YYYY"),
      };

      dispatch(editClient({ editClientPayload }))
        .then((result: any) => {
          if (result.payload.result === true) {
            handleCloseEditClientDialog(result.payload.message);
          } else {
            setEditClientError(true);
            setEditClientErrorMessage(result.payload.message);
          }
        })
        .catch((error) => {
          setEditClientError(true);
          setEditClientErrorMessage("Unable to update client");
        });
    },
  });

  const initialEditClient = () => {
    if (editClientForm && editClientData) {
      setEditClientError(false);
      setEditClientErrorMessage("");
      setProgramErrorEditClient(false);
      setProgramErrorMessageEditClient("");

      editClientForm.setFieldValue("client_id", editClientData?.customer_id);
      editClientForm.setFieldValue(
        "client_name",
        editClientData?.customer_name
      );
      editClientForm.setFieldValue("programs", editClientData?.programs);
    }
  };

  useEffect(() => {
    initialEditClient();
  }, [editClientData]);

  useEffect(() => {
    enableSubmit();
  }, [editClientForm.values]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleCloseEditClientDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Edit Client
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseEditClientDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addClientLoading} />
      <form onSubmit={editClientForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Client ID"
                  inputProps={{ type: "number" }}
                  label="Client ID"
                  id="client_id"
                  name="client_id"
                  variant="outlined"
                  fullWidth
                  defaultValue={
                    editClientData?.client_id ? editClientData?.client_id : ""
                  }
                  value={editClientForm.values.client_id}
                  error={
                    editClientForm.touched.client_id &&
                    Boolean(editClientForm.errors.client_id)
                  }
                  helperText={editClientForm.errors.client_id}
                  onChange={editClientForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  placeholder="Client Name"
                  label="Client Name"
                  id="client_name"
                  name="client_name"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  defaultValue={
                    editClientData?.client_name
                      ? editClientData?.client_name
                      : ""
                  }
                  value={editClientForm.values.client_name}
                  error={
                    editClientForm.touched.client_name &&
                    Boolean(editClientForm.errors.client_name)
                  }
                  helperText={editClientForm.errors.client_name}
                  onChange={editClientForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <Autocomplete
                  id="programs"
                  multiple
                  defaultValue={
                    editClientData?.programs ? editClientData?.programs : []
                  }
                  value={editClientForm.values.programs}
                  onChange={(_: SyntheticEvent, newValue: any | null) => {
                    setEditClientError(false);
                    setEditClientErrorMessage("");
                    if (newValue) {
                      editClientForm.setFieldValue("programs", newValue);
                    } else {
                      editClientForm.setFieldValue("programs", undefined);
                    }
                  }}
                  options={allPrograms ? allPrograms : []}
                  getOptionLabel={(option: any) =>
                    option.program_name ? option.program_name : undefined
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.program_index === value?.program_index
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Programs"
                      label={"Programs"}
                      error={programErrorEditClient}
                      helperText={programErrorMessageEditClient}
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ padding: "5px" }} xs={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="checkOut"
                    value={dayjs(editClientForm.values.create_date)}
                    onChange={(value) => {
                      editClientForm.setFieldValue("create_date", value);
                    }}
                    views={["year", "month", "day"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            {editClientError && (
              <Grid
                container
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ color: "red" }}>
                  {editClientErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid" }}>
          <Button
            variant="outlined"
            onClick={() => handleCloseEditClientDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
            disabled={enableSubmitEditClient}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditClientDialog;
