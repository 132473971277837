import { useState } from "react";
import { AddressElement, PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { validatCard } from "../../../utils/utils";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Cards, { Focused } from "react-credit-cards-2";

interface PaymentFormProps {
  handleClose: any;
  paymentIntentId: string;
}

const PaymentForm = (props: PaymentFormProps) => {
  const { handleClose, paymentIntentId } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);

  const [submited, setSubmitted] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [address, setAddress] = useState<any>({});

  const [state, setState] = useState<any>({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
    zipCode: "",
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const cardInfoVerification = () => {
    const cardErros = validatCard(state);

    setErrors(cardErros);

    if (cardErros.isValid) {
      return true;
    }
    return false;
  };

  //   const { paymentLoading } = useAppSelector((state: any) => state.payments);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    const check = cardInfoVerification();

    if (check === false) return;

    // dispatch(processPayment({ paymentInfo: paymentPaylod }));

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    // await stripe
    //   .confirmPayment({
    //     elements,
    //     confirmParams: {
    //       // Make sure to change this to your payment completion page
    //       return_url: `${window.location.origin}`,
    //     },
    //     redirect: "if_required",
    //   })
    //   .then(function (confirmPyamentResult) {
    //     if (confirmPyamentResult.error) {
    //       if (
    //         confirmPyamentResult.error?.type === "card_error" ||
    //         confirmPyamentResult.error?.type === "validation_error"
    //       ) {
    //         setMessage(confirmPyamentResult.error?.message);
    //       } else {
    //         setMessage("Unable to process payment. Please try again later");
    //       }
    //     } else {
    //       const paymentPaylod = {
    //         user_id: userInfo?.user_id,
    //         email: userInfo?.email,
    //         stripe_customer_id: userInfo?.stripe_customer_id,
    //         property_name: selectedPropertyDetails.property_name,
    //         property_id: selectedPropertyDetails.property_id,
    //         hostfully_id: selectedPropertyDetails.hostfully_property_id,
    //         check_in: dayjs(currentDestination?.checkIn).format(
    //           "YYYY-MM-DDTHH:mm:ss"
    //         ),
    //         check_out: dayjs(currentDestination?.checkOut).format(
    //           "YYYY-MM-DDTHH:mm:ss"
    //         ),
    //         cardAddres: address,
    //         totalPrice: totalPrice,
    //         taxes: taxes,
    //         lvlpm_fee: lvlFee,
    //         price_per_day_ca: pricePerDayNonCa,
    //         stay_price: stayPrice,
    //         paymentIntentId: paymentIntentId,
    //         nights: nights,
    //         guests: guests,
    //         stripe_payment_method:
    //           confirmPyamentResult?.paymentIntent?.payment_method,
    //       };
    //       dispatch(processPayment({ ...{ paymentInfo: paymentPaylod } }))
    //         .then(async (paymentResult: any) => {
    //           setIsProcessing(false);
    //         })
    //         .catch((error) => {
    //           displayConsoleError("processPayment", error);
    //         });
    //     }
    //     return confirmPyamentResult;
    //   });
  };

  const handleInputChange = (evt: any) => {
    const { name, value } = evt.target;

    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt: any) => {
    setState((prev: any) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <Grid item xs={12} sx={{ padding: "15px" }}>
      {/* <Spinner loading={paymentLoading} /> */}

      <form id="payment-form" onSubmit={handleSubmit}>
        <Grid container item xs={12} sx={{ border: "1px solid red" }}>
          <Grid item xs={6}>
            <Cards
              number={state.number}
              expiry={state.expiry}
              cvc={state.cvc}
              name={state.name}
              focused={state.focus as Focused}
            />
          </Grid>
          <Grid container item xs={6} sx={{ padding: "2%" }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                name="number"
                placeholder="Card Number"
                value={state.number}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </Grid>
            <Grid container sx={{}}>
              <Grid item xs={6} sx={{}}>
                <TextField
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="Valid Thru"
                  inputProps={{
                    min: 0,
                    maxLength: 5,
                    pattern: "dd/dd",
                  }}
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </Grid>
              <Grid item xs={6} sx={{}}>
                <TextField
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVC"
                  inputProps={{
                    min: 0,
                    maxLength: 4,
                    pattern: "d{3,4}",
                  }}
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "2%" }}>
          <AddressElement
            className={"text-sm text-gray-700 border rounded"}
            options={{ mode: "shipping" }}
            onChange={(event) => {
              setAddress(event.value);
              setState((prev: any) => ({
                ...prev,
                cardName: event.value.name ? event.value.name : "",
                zipCode: event.value.address.postal_code
                  ? event.value.address.postal_code
                  : "",
              }));
            }}
          />
        </Grid>
        {submited && !errors?.isValid && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography style={{ color: "red" }}>{errors.message}</Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0px 10px 0px",
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleClose("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isProcessing || !stripe || !elements}
            variant="contained"
            onClick={(e) => handleSubmit(e)}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
          >
            Confirm Payment
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default PaymentForm;
