import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
interface CancelSubscriptionDialogProps {
  open: any;
  handleClose: any;
  CancelSubscriptionData: any;
}

const CancelSubscriptionDialog = (props: CancelSubscriptionDialogProps) => {
  const { open, handleClose } = props;
  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>Cancel Subscription User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose} autoFocus>
          Cancel Subscrition
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
