import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { passwordInfo } from "../../types/formTypes";
import {
  reset,
  setPreviousLocation,
} from "../../store/features/authentication/autehnticationSlice";
import {
  resetPassword,
  resetPasswordLinkVerification,
} from "../../store/thunks/authenticationThunk";

const ResetPasswordVerification = () => {
  const location = useLocation();
  const { token } = useParams();
  const {
    resetPasswordLinkVerificationLoading,
    resetPasswordLoading,
    error,
    loggedIn,
  } = useAppSelector((state: any) => state.authentication);

  const loginInitialValues: passwordInfo = {
    passwordMatch: "",
    password: "",
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfrim, setShowPasswordConfrim] = useState(false);

  const validateLogin = Yup.object({
    passwordMatch: Yup.string().oneOf(
      [Yup.ref("password"), undefined],
      "Passwords must match"
    ),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/,
        "Must contain At least One Upercase, At least One special character, At least One digit."
      ),
  });
  const passwordResetForm = useFormik({
    initialValues: loginInitialValues,
    validationSchema: validateLogin,
    onSubmit: (values: passwordInfo) => {
      if (token && token !== "") {
        dispatch(setPreviousLocation(location.pathname));
        dispatch(resetPassword({ ...values, token }))
          .then((result) => {
            if (result?.payload?.result) {
              navigate("/login");
            } else {
              navigate("/");
            }
          })
          .catch(() => {
            navigate("/");
          });
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfrim((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(reset());
    if (loggedIn) {
      navigate("/");
    }
    dispatch(reset());
    if (token && token !== "") {
      dispatch(resetPasswordLinkVerification({ token: token }))
        .then((result) => {
          if (!result?.payload?.status) {
            navigate("/");
          }
        })
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, [dispatch, loggedIn, navigate, token]);

  return (
    <Grid sx={{ minHeight: "600px" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Spinner loading={resetPasswordLoading} />
        <Spinner loading={resetPasswordLinkVerificationLoading} />
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            zIndex: 2,
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              zIndex: 2,
              width: "100%",
              maxWidth: "600px",
              p: 3,
            }}
            elevation={3}
          >
            <form onSubmit={passwordResetForm.handleSubmit}>
              <Grid container sx={{ gap: 1.5 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    backgroundColor: "#00b93f",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", color: "#ccc" }}
                  >
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Password</Typography>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                    name="password"
                    fullWidth
                    value={passwordResetForm.values.password}
                    error={
                      passwordResetForm.touched.password &&
                      Boolean(passwordResetForm.errors.password)
                    }
                    onChange={passwordResetForm.handleChange}
                  />
                  {passwordResetForm.touched.password &&
                    Boolean(passwordResetForm.errors.password) && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                      >
                        <Typography>
                          {passwordResetForm.errors.password}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Confirm Password</Typography>
                  <OutlinedInput
                    id="outlined-adornment-password-match"
                    type={showPasswordConfrim ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordConfrim ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Confirm Password"
                    name="passwordMatch"
                    fullWidth
                    value={passwordResetForm.values.passwordMatch}
                    error={
                      passwordResetForm.touched.passwordMatch &&
                      Boolean(passwordResetForm.errors.passwordMatch)
                    }
                    onChange={passwordResetForm.handleChange}
                  />
                  {passwordResetForm.touched.passwordMatch &&
                    Boolean(passwordResetForm.errors.passwordMatch) && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                      >
                        <Typography>
                          {passwordResetForm.errors.passwordMatch}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                {error && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{error?.data?.message}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#56baed" }}
                    onClick={() => passwordResetForm.submitForm}
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ResetPasswordVerification;
