import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProductInfo } from "../../../../types/formTypes";
import { addProduct } from "../../../../store/thunks/systemManagementThunk";
import { Product_Type_Option } from "../../../../utils/constants";

interface CreateProductDialogProps {
  open: any;
  handleCreateProductClose: any;
}

const CreateProductDialog = (props: CreateProductDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleCreateProductClose } = props;

  const { allPrograms, addUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const handleCloseCreateProductDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setAddProductError(false);
    setAddProductErrorMessage("");
    setProductTypeError(false);
    setProductTypeErrorMessage("");
    handleCreateProductClose(message);
  };

  let createProductInitialValues: ProductInfo = {
    product_name: "",
    product_description: "",
    product_price: "",
    product_type: undefined,
  };

  const [addProductError, setAddProductError] = useState(false);
  const [addProductErrorMessage, setAddProductErrorMessage] = useState("");

  const [productTypeError, setProductTypeError] = useState(false);
  const [productTypeErrorMessage, setProductTypeErrorMessage] = useState("");

  const validateAddProduct = Yup.object({
    product_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is Required"),
    product_description: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is Required"),
    product_price: Yup.number().required("Last Name is Required"),
    product_type: Yup.mixed(),
  });

  const addProductForm = useFormik({
    initialValues: createProductInitialValues,
    validationSchema: validateAddProduct,
    enableReinitialize: true,
    onSubmit: (values: ProductInfo) => {
      setAddProductError(false);
      setAddProductErrorMessage("");
      setProductTypeError(false);
      setProductTypeErrorMessage("");

      if (values.product_type) {
        setProductTypeError(false);
        setProductTypeErrorMessage("");
      } else {
        setProductTypeError(true);
        setProductTypeErrorMessage("programs is required");
        return;
      }

      const createProductPayload = {
        name: values.product_name,
        price: values.product_price,
        description: values.product_description,
        product_type: values.product_type.value,
      };

      dispatch(addProduct({ createProductPayload })).then((result: any) => {
        if (result.payload.result === true) {
          handleCreateProductClose(result.payload.message);
        } else {
          setAddProductError(true);
          setAddProductErrorMessage(result.payload.message);
        }
      });
    },
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleCloseCreateProductDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          {addProductForm ? "Edit Product" : "Add Product"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseCreateProductDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addUserLoading} />
      <form onSubmit={addProductForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Product Name"
                  inputProps={{}}
                  label="Product ID"
                  id="product_name"
                  name="product_name"
                  variant="outlined"
                  fullWidth
                  value={addProductForm.values.product_name}
                  error={
                    addProductForm.touched.product_name &&
                    Boolean(addProductForm.errors.product_name)
                  }
                  helperText={addProductForm.errors.product_name}
                  onChange={addProductForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  placeholder="Product Description"
                  label="Product Description"
                  id="product_description"
                  name="product_description"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={addProductForm.values.product_description}
                  error={
                    addProductForm.touched.product_description &&
                    Boolean(addProductForm.errors.product_description)
                  }
                  helperText={addProductForm.errors.product_description}
                  onChange={addProductForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  placeholder="Product Price"
                  label="Product Price"
                  id="product_price"
                  name="product_price"
                  variant="outlined"
                  inputProps={{ type: "number" }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={addProductForm.values.product_price}
                  error={
                    addProductForm.touched.product_price &&
                    Boolean(addProductForm.errors.product_price)
                  }
                  helperText={addProductForm.errors.product_price}
                  onChange={addProductForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <Autocomplete
                  id="programs"
                  value={addProductForm.values.product_type}
                  onChange={(_: SyntheticEvent, newValue: any | null) => {
                    if (newValue) {
                      addProductForm.setFieldValue("product_type", newValue);
                    } else {
                      addProductForm.setFieldValue("product_type", undefined);
                    }
                  }}
                  options={Product_Type_Option}
                  getOptionLabel={(option: any) => option?.label}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Product Type"
                      label={"Product Type"}
                      error={productTypeError}
                      helperText={productTypeErrorMessage}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {addProductError && (
              <Grid
                container
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ color: "red" }}>
                  {addProductErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid" }}>
          <Button
            variant="outlined"
            onClick={() => handleCloseCreateProductDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateProductDialog;
