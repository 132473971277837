import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniSpinner from "../../../reusableComponents/miniSpinner/miniSpinner";
import { useAppSelector } from "../../../../hooks/hooks";
const EquipmentDashboard = () => {
  const { equipmentAnalyticLoading } = useAppSelector(
    (state: any) => state.equipmentManagement
  );
  return (
    <Grid>
      <Grid>Dashboard</Grid>
      <Grid>
        {equipmentAnalyticLoading ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              border: "1px solid grey",
            }}
          >
            <MiniSpinner />
          </Grid>
        ) : (
          <Grid></Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EquipmentDashboard;
