import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  getTrainingCourse,
  getTrainingModuleSummary,
  getTrainingModule,
} from "../../../store/thunks/portalManagementThunk";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import TableTemplate from "../../reusableComponents/tableTemplate/tableTemplate";
import DashboardHeader from "../../reusableComponents/dashboardHeader/dashboardHeader";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { exportToExcel } from "../../../utils/utils";

const Learning = () => {
  const dispatch = useAppDispatch();
  const {
    selectedClient,
    trainingCourseRawData,
    trainingModuleSummaryRawData,
    trainingModuleRawData,
  } = useAppSelector((state: any) => state.portalManagement);

  useEffect(() => {
    if (selectedClient) {
      dispatch(
        getTrainingCourse({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getTrainingModuleSummary({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getTrainingModule({
          customer_id: selectedClient.customer_id,
        })
      );
    }
  }, []);

  const [trainingModuleTableData, setTrainingModuleTableData] = useState<any>(
    []
  );
  const [courseOption, setcourseOption] = useState<any>([]);
  const [trainingModuleSummaryTableData, setTrainingModuleSummaryTableData] =
    useState<any>([]);

  const [selectedCourse, setselectedCourse] = useState({
    value: "Overall",
    label: "Overall",
  });

  const trainingFilterData = () => {
    if (trainingCourseRawData) {
      const tempCourseOption: any[] = [];
      trainingCourseRawData?.student_list_dict?.Unique_student.forEach(
        (element: any) => {
          tempCourseOption.push({ label: element, value: element });
        }
      );
      setcourseOption(tempCourseOption);
    }
    if (trainingModuleRawData) {
      setTrainingModuleTableData(
        trainingModuleRawData?.course_summary_dict?.data
      );
    }
    if (trainingModuleSummaryRawData) {
      setTrainingModuleSummaryTableData(
        trainingModuleSummaryRawData?.student_summary_dict[selectedCourse.value]
      );
    }
  };

  useEffect(() => {
    if (
      trainingCourseRawData ||
      trainingModuleSummaryRawData ||
      trainingModuleRawData
    ) {
      trainingFilterData();
    }
  }, [
    trainingCourseRawData,
    trainingModuleSummaryRawData,
    trainingModuleRawData,
    selectedCourse,
  ]);

  const coursesColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "Course",
            header: "Course",
          },
          {
            accessorKey: "Assigned",
            header: "Assigned",
          },
          {
            accessorKey: "Completed",
            header: "Completed",
          },
        ],
      },
    ],
    []
  );

  const coursesTable = useMaterialReactTable({
    columns: coursesColumns,
    data: trainingModuleTableData ? trainingModuleTableData : [],
    state: {
      //isLoading: rankByWgsDetailsLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: false,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            exportToExcel(table.getPrePaginationRowModel().rows, "Courses Data")
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            exportToExcel(table.getRowModel().rows, "Courses Data")
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
      </Box>
    ),
  });

  const studentsColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "Name",
            header: "Name",
          },
          {
            accessorKey: "Course",
            header: "Course",
          },
          {
            accessorKey: "Assigned",
            header: "Assigned",
          },
          {
            accessorKey: "Started",
            header: "Started",
          },
          {
            accessorKey: "Completed",
            header: "Completed",
          },
          {
            accessorKey: "Percent",
            header: "Percent",
          },
          {
            accessorKey: "Pre_Test",
            header: "Pre_Test",
          },
          {
            accessorKey: "Final",
            header: "Final",
          },
        ],
      },
    ],
    []
  );

  const studentsTable = useMaterialReactTable({
    columns: studentsColumns,
    data: trainingModuleSummaryTableData ? trainingModuleSummaryTableData : [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      // isLoading: rankByWgsDetailsLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: false,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            exportToExcel(table.getPrePaginationRowModel().rows, "Courses Data")
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            exportToExcel(table.getRowModel().rows, "Courses Data")
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    if (
      trainingCourseRawData ||
      trainingModuleSummaryRawData ||
      trainingModuleRawData
    )
      trainingFilterData();
  }, [
    trainingCourseRawData,
    trainingModuleSummaryRawData,
    trainingModuleRawData,
  ]);

  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
        }}
      >
        <DashboardHeader
          title={selectedClient.customer_name}
          headerType={"learning"}
        />
        <Grid container sx={{}}>
          <Grid container item xs={12} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Courses
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "#FFFFFF",
                  gap: 2,
                }}
              >
                <Grid container sx={{}}>
                  <TableTemplate tableData={coursesTable} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Courses Details
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "#FFFFFF",
                  gap: 2,
                }}
              >
                <Grid container item xs={12}>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <FormControl fullWidth>
                      <Autocomplete
                        id="sales_rep"
                        value={selectedCourse}
                        onChange={(_: SyntheticEvent, newValue: any) => {
                          if (newValue) {
                            setselectedCourse(newValue);
                          } else {
                            setselectedCourse({
                              value: "Overall",
                              label: "Overall",
                            });
                          }
                          trainingFilterData();
                        }}
                        options={courseOption}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Courses"
                            placeholder="Select Courses"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container sx={{}}>
                  <TableTemplate tableData={studentsTable} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Learning;
