import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_clients from "./../../assets/our_client_banner.jpg";
import our_clients_1 from "./../../assets/anderson-feature.jpg";
import Andersons from "./../../assets/anderson.png";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const Anderson = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_clients})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Clients
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{}}>
          <Grid
            item
            xs={10}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#fff",
              backgroundColor: "#fff",
              height: "400px",
            }}
          ></Grid>
          <Grid
            item
            xs={12}
            sx={{
              margin: "20px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                backgroundImage: `url(${Andersons})`,
                backgroundRepeat: "no-repeat",
                color: "#fff",
                backgroundColor: "#fff",
                height: "80px",
              }}
            ></Grid>
          </Grid>
          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              See Your Results as Clear as Day
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Zintoro helped Andersons’ Sales and Service focus on customer
              retention to grow its business across the U.S.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              The world has changed for retail businesses, and Andersons’ Sales
              and Service is changing with it. A lawn and garden dealership with
              three retail locations in the Louisville metro area, Andersons’
              Sales has seen their online business more than triple since 2021,
              growing to 20,000 shipments expected in 2023. They transformed
              from a largely local dealership to one that ships to all 50
              states.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              To achieve goals, Greg Hydes, general manager of online sales for
              Andersons’, focuses on his numbers: sales, accounts, invoices,
              retention rate. “Working in a family-owned business, relying on
              numbers helps take the emotions out of making decisions that
              impact sales,” he says.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              To get the numbers he needs, Hydes relies on Zintoro, a
              forecasting tool driven by artificial intelligence that helps
              businesses document their current situation, forecast with
              certainty, and understand the actions they need to take to achieve
              their growth objectives. Since the company began using Zintoro,
              its focus has shifted to the customer. “We’re actively pursuing
              customer retention,” says Hydes. “You can retain seven customers
              for the cost of obtaining a new customer. Zintoro showed us the
              most profitable way to do business. You can see the results clear
              as day.”
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              How it works
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              The program begins with an initial analysis of three years of
              sales data and recommendations to achieve growth. An API with
              Constellation Software facilitates the exchange of data. Drawing
              on key metrics, including customer retention and engagement, the
              number of new customers, and customer satisfaction, Zintoro offers
              a comprehensive examination of the existing and potential
              structure for your business. Customers meet monthly with Zintoro
              managers to discuss how to grow most effectively. Progress is
              measured against expectations, and clients receive specific
              recommendations to achieve their goals.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              “The program identifies what you’re doing right and what you’re
              not doing well,” says Hydes. “I use it for budgeting. It forecasts
              very well, and it gives you a direction.”
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Hydes can look at results for the overall business, as well as
              separating out the parts and service departments. When he first
              began using the program, he was frustrated by not knowing the
              return on investment of print advertising, postcards, and email
              marketing. Now, through a marketing section in Zintoro, Hydes can
              clearly see how emails and calling programs impact customer
              transactions.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              The Zintoro dashboard easily corrals data and enables Hydes to
              spend his time reviewing the recommendations and planning future
              actions, rather than manually retrieving and analyzing data. Hydes
              also finds value in the monthly calls.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              “It’s not just a dashboard, and you are not left alone to figure
              it out. It’s six months to a year of monthly calls, where you
              review the numbers, and you can look at your dealership through
              another set of eyes,” he says. “Zintoro has smart people who
              understand our business.”
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px " }}
            >
              Success with Zintoro
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Hydes manages a four-person ecommerce team that is responsible for
              writing web copy and search engine optimization for a half million
              SKUs. The website must be updated continually to reflect changes
              in pricing and engineering. Winsby Inc., their b2b marketing
              agency, develops and distributes emails and call programs that tie
              into Zintoro.
            </Typography>
            <Typography>
              But the online business isn’t the only part of the business that’s
              growing with the help of Zintoro and Winsby. “The emails are
              really keeping traffic up in the stores,” says Hydes.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Working alongside Zintoro, Andersons’ Sales & Service has taken on
              numerous initiatives, such as an innovative fleet management
              program called Andersons’ Garage designed for commercial and
              government organizations who manage a fleet of equipment and do
              their own maintenance.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Hydes highly recommends Zintoro but at the same time cautions that
              results depend on your actions. “You have to be willing to put in
              the work,” says Hydes. “You need to be able to execute.”
              Andersons’ Sales & Service clearly shows the power of Zintoro in
              the hands of a dealer committed to growth.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Anderson;
