import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_clients from "./../../assets/our_client_banner.jpg";
import our_clients_1 from "./../../assets/our_client_img1.jpg";
import our_clients_2 from "./../../assets/our_client_img2.png";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const Bergquist = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_clients})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Clients
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{}}>
          <Grid
            item
            xs={10}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#fff",
              backgroundColor: "#fff",
              height: "400px",
            }}
          ></Grid>
          <Grid item xs={12} sx={{}}>
            <Grid
              item
              xs={4}
              sx={{
                backgroundImage: `url(${our_clients_2})`,
                backgroundRepeat: "no-repeat",
                color: "#fff",
                backgroundColor: "#fff",
                height: "120px",
              }}
            ></Grid>
          </Grid>
          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              AI-Powered Software Helps Grow Active Accounts for this Propane
              Equipment Distributor
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Recency, frequency and monetary value data from Zintoro becomes a
              powerful tool for Bergquist sales team
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Many business software programs provide data, but programs that
              make that data easy to use are the ones that deliver results.
              According to Don Montroy, Vice President of Bergquist, a leading
              propane equipment distributor, working with Zintoro provides easy
              access to data on the recency and frequency of purchases as well
              as the monetary value of customers. “We had other business
              intelligence tools tied to our ERP system that we were using, but
              none laid out the data to really look at those key metrics.”
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              How it works
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Zintoro’s AI-powered software analyzes a company’s invoices to
              identify key insights and make predictions about future sales.
              When Bergquist began working with Zintoro about five years ago,
              they were trying to control the churn of customers by engaging
              with customers who made a purchase 9 to 12 weeks ago. An analysis
              of their data showed that if customers hadn’t made a purchase
              within this timeframe, they were highly likely to stop purchasing
              from them.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              “Zintoro made it easy to provide that data to salespeople, who
              could then prioritize their conversations and visits with
              customers based on a convenient spreadsheet,” says Montroy. “It’s
              all about identifying and then engaging those customers, whether
              it’s through email, outside sales paths or direct mail.”
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Zintoro software enabled Bergquist to visualize customers as
              belonging to one of three groups based on their RFM score
              (Recency, Frequency and Monetary Value): stable customers, Risk 1
              customers and Risk 2 customers. “It was a good way to segment our
              customer base to determine which ones we need to make a priority,”
              notes Montroy. The company chose to focus on stable customers and
              those that are in the Risk 1 (moderate risk) category.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Information on new customers was also key to the distributor. “It
              was easy to identify customers that were new to Bergquist,” says
              Montroy. “We could then help onboard them to our company.” The
              follow-up action might be a call or email to check on the service
              or to ask if there is anything else they might need. All of
              Bergquist’s 16 outside sales reps and 12 inside sales reps have
              access to data through an online portal that helps them track the
              actions of approximately 3,800 active customers.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Through Zintoro, the company has also identified another segment
              of their business they call “non-propane.” These are not their
              core customers that are propane retailers, but are other types,
              such as HVAC companies, whom they could potentially focus on to
              grow.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px " }}
            >
              Results
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              According to Montroy, Bergquist is now seeing the results of their
              efforts. In the past 18 months, the number of active accounts has
              grown 1% to 1 1/2% after years of being flat. “It doesn’t sound
              like much but in this industry it’s big deal to grow active
              accounts by a percent per year.”
            </Typography>
            <Typography>
              Montroy also has confidence in the forecasting numbers that come
              from Zintoro. “It usually tracks pretty closely, within a percent
              or so,” says Montroy. “This makes it easier to set sales goals for
              the company.”
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              In addition to providing analytics. Montroy finds value in
              quarterly calls with Zintoro’s owner, Steve Clegg, who is also
              managing director of Winsby, a company that provides marketing
              services to b2b companies. “Steve has experience working with
              manufacturers and distributors across multiple industries, and he
              can offer insights we might not have thought of,” says Montroy.
              “It’s extremely valuable.”
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Montroy says the software is so reasonably priced, compared to
              other business intelligence tools, that they don’t even look at
              the return on investment. “We know we’re getting a return on it,”
              says Montroy.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              However, Montroy adds that companies need to be committed to
              acting on the data to realize the value fully. “You need to look
              at the data, analyze it and put a plan in place,” he says.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Zintoro makes it easy.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Bergquist;
