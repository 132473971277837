import React, { useContext, useEffect, useState } from "react";
import {
  Elements,
  AddressElement,
  CardElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import PaymentForm from "./paymentForm";
import CloseIcon from "@mui/icons-material/Close";
import {
  cancelPaymentIntent,
  paymentConfig,
  paymentIntent,
} from "../../../store/thunks/systemManagementThunk";
import "react-credit-cards-2/dist/es/styles-compiled.css";

interface SubmitPaymentDialogProps {
  open: boolean;
  handleClose: () => void;
  paymentDialogData: any;
}
const SubmitPaymentDialog = (props: SubmitPaymentDialogProps) => {
  const { open, handleClose, paymentDialogData } = props;

  const { userInfo } = useAppSelector((state: any) => state.authentication);

  console.log("paymentDialogData", paymentDialogData);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripePromise, setStripePromise] = useState<any>(null);

  const handleClosePaymentDialog = (message: string, reason: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    if (paymentIntentId && message === "") {
      dispatch(cancelPaymentIntent({ payment_intent_id: paymentIntentId }));
      setPaymentIntentId("");
      setClientSecret("");
    }

    handleClose();
  };

  useEffect(() => {
    dispatch(paymentConfig({})).then((paymentConfigResult: any) => {
      console.log("paymentConfigResult", paymentConfigResult);
      if (paymentConfigResult?.payload?.result) {
        setStripePromise(
          loadStripe(paymentConfigResult.payload?.publishableKey)
        );
      }
    });
  }, [open]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleClosePaymentDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Confirmation & Payment
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleClosePaymentDialog("", "")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          item
          md={6}
          xs={12}
          sx={{ padding: "1%", display: "flex", flexDirection: "row", gap: 2 }}
        >
          <Typography>Description: {paymentDialogData?.description}</Typography>
          <Typography>Amount: {paymentDialogData?.price}</Typography>
        </Grid>
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <PaymentForm
              handleClose={handleClosePaymentDialog}
              paymentIntentId={paymentIntentId}
            />
          </Elements>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SubmitPaymentDialog;
