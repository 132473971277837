import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import results from "./../../assets/ZIN-Web-Results-v1.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const Results = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${results})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            The Results
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 5% 10%",
        }}
      >
        <Typography variant="h4" sx={{ color: "#3aae4a", fontWeight: "bold" }}>
          Reach your revenue goals with an accurate forecast
        </Typography>
        <List sx={{ listStyleType: "disc", paddingLeft: "10px" }}>
          <ListItem sx={{ display: "list-item" }}>
            Know exactly where your business is headed with relevant business
            metrics
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Achieve real, predictable growth
          </ListItem>
        </List>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            We run your numbers, so you can see the future for your company and
            how to change it for the better. The accuracy of Zintoro forecasts
            is over 95%, allowing you to make key decisions with confidence. Our
            financial experts will walk you through the numbers every month and
            provide insights about trends that may be happening with your
            business—good or bad.
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            How to improve your results
          </Typography>
          <Typography>
            Understanding your business metrics is the first step towards
            achieving your revenue goals. The next step is to use those numbers
            as a starting point and produce better results moving forward. Here
            are the best ways to improve key business metrics:
          </Typography>
        </Grid>

        <List sx={{ listStyleType: "disc", paddingLeft: "10px" }}>
          <ListItem sx={{ display: "list-item" }}>
            <span style={{ fontWeight: "bold" }}>
              {" "}
              Customer retention rate{" "}
            </span>{" "}
            — Improve this metric by up to 30% by regularly conducting customer
            satisfaction surveys.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <span style={{ fontWeight: "bold" }}> Revenue per customer </span> —
            The best way to increase revenue per customer is to hold on to those
            customers. For example, for equipment dealers, customers purchase
            2.9X more equipment, 9.1X more rentals, 4.1X more service, and 5.6X
            more parts in the third year of working with you.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <span style={{ fontWeight: "bold" }}> Purchase frequency </span> —
            Distributing emails to customers and prospects increases how often
            customers purchase from you. In fact, customers on your email list
            will purchase two to three times more often than customers who are
            not receiving emails.
          </ListItem>
        </List>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            If you want to improve the results for your company and reach your
            revenue goals, the first step is a business metrics forecast by
            Zintoro. See where and how to make changes today!
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            <NavLink
              style={{
                textDecoration: "none",
                color: "#00467f",
              }}
              to={"/contactus"}
            >
              Schedule a Consultation Today
            </NavLink>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Results;
