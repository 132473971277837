import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { CssBaseline, Grid } from "@mui/material";
import Layout from "./components/commonComponets/layout/layout";
import Header from "./components/commonComponets/header/header";
import Home from "./pages/homePage/home";
import Login from "./pages/loginPage/login";
import GettingStarted from "./pages/gettingStartedPage/gettingStarted";
import AboutUs from "./pages/aboutUsPage/aboutUs";
import ContactUs from "./pages/contactUsPage/countactUs";
import HowItWorks from "./pages/howItWorksPage/howItWorks";
import OurBlog from "./pages/ourBlogPage/ourBlog";
import OurClients from "./pages/ourClientsPage/ourClients";
import OurPartners from "./pages/ourPartnersPage/ourPartners";
import Resetpassword from "./pages/resetPasswordPage/resetPassword";
import Results from "./pages/results/results";
import WhatYouLearn from "./pages/whatYouLearnPage/whatYouLearn";
import WhyZintoro from "./pages/whyZintoroPage/whyZintoro";
import Footer from "./components/commonComponets/footer/footer";
import Anderson from "./pages/ourClientsPage/andersons";
import Bergquist from "./pages/ourClientsPage/bergquist";
import CustomerRetention from "./pages/ourBlogPage/customerRetention";
import GeographicMarket from "./pages/ourBlogPage/geographicMarket";
import IndustryAnalysis from "./pages/ourBlogPage/industryAnalysis";
import RevenueAnalyticsGoals from "./pages/ourBlogPage/revenueAnalyticsGoals";
import UnderstandPurchases from "./pages/ourBlogPage/understandPurchases";
import WhereYouAre from "./pages/ourBlogPage/whereYouAre";
import BusinessRetention from "./pages/ourBlogPage/businessRetention";
import RevenueGoals from "./pages/ourBlogPage/revenueGoals";
import Account from "./pages/account/account";
import PrivateRoutes from "./components/reusableComponents/protectedRoute/protectedRoute";
import { useAppSelector } from "./hooks/hooks";
import ResetPasswordVerification from "./pages/resetPasswordVerification/resetPasswordVerification";
import SystemManagement from "./pages/systemManagementPage/systemManagement";

function App() {
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid sx={{ minWidth: "1400px" }}>
        <Header />
        <Grid>
          <Routes>
            <Route path="/" element={<Layout children={""} />}>
              <Route index element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/gettingstarted" element={<GettingStarted />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/howitworks" element={<HowItWorks />} />
              <Route path="/ourblog" element={<OurBlog />} />
              <Route
                path="/ourblog/customerretention"
                element={<CustomerRetention />}
              />
              <Route
                path="/ourblog/geographicmarket"
                element={<GeographicMarket />}
              />
              <Route
                path="/ourblog/industryanalysis"
                element={<IndustryAnalysis />}
              />
              <Route
                path="/ourblog/revenueanalyticsgoals"
                element={<RevenueAnalyticsGoals />}
              />
              <Route
                path="/ourblog/understandpurchases"
                element={<UnderstandPurchases />}
              />
              <Route
                path="/ourblog/businessretention"
                element={<BusinessRetention />}
              />
              <Route path="/ourblog/revenuegoals" element={<RevenueGoals />} />
              <Route path="/ourblog/whereyouare" element={<WhereYouAre />} />
              <Route path="/ourclients" element={<OurClients />} />
              <Route path="/ourclients/andersons" element={<Anderson />} />
              <Route path="/ourclients/bergquist" element={<Bergquist />} />
              <Route path="/ourpartners" element={<OurPartners />} />
              <Route path="/resetpassword" element={<Resetpassword />} />
              <Route path="/whatyoulearn" element={<WhatYouLearn />} />
              <Route path="/results" element={<Results />} />
              <Route path="/whyzintoro" element={<WhyZintoro />} />
              <Route
                path="/resetpasswordverification/:token"
                element={<ResetPasswordVerification />}
              />

              <Route element={<PrivateRoutes />}>
                <Route path="/dashboard" element={<Account />} />
              </Route>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/systemManagement"
                  element={<SystemManagement />}
                />
              </Route>
              {/* <Route element={<PrivateRoutes />}>
                <Route
                  path="/helpSupport"
                  element={<SystemManagement />}
                />
              </Route> */}
              {/*
                <Route
                  path="/emailVerification/:token"
                  element={<EmailVerification />}
                />
                <Route path="/management" element={<Account />} /> */}
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </Grid>
        {!loggedIn && <Footer />}
      </Grid>
    </React.Fragment>
  );
}

export default App;
