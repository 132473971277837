import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProductInfo } from "../../../../types/formTypes";
import { Product_Type_Option } from "../../../../utils/constants";
import {
  addProduct,
  editProduct,
} from "../../../../store/thunks/systemManagementThunk";
// import { editProduct } from "../../../../store/thunks/systemManagementThunk";

interface EditProductDialogProps {
  open: any;
  handleEditProductClose: any;
  editProductData: any;
}

const EditProductDialog = (props: EditProductDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleEditProductClose, editProductData } = props;

  const { allPrograms, addProductLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const handleCloseEditProductDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setEditProductError(false);
    setEditProductErrorMessage("");
    setProductTypeError(false);
    setProductTypeErrorMessage("");
    handleEditProductClose(message);
  };

  let editProductInitialValues: ProductInfo = {
    product_name: "",
    product_description: "",
    product_price: "",
    product_type: undefined,
  };

  const [editProductError, setEditProductError] = useState(false);
  const [editProductErrorMessage, setEditProductErrorMessage] = useState("");

  const [productTypeError, setProductTypeError] = useState(false);
  const [productTypeErrorMessage, setProductTypeErrorMessage] = useState("");

  const validateEditProduct = Yup.object({
    product_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Product Name is Required"),
    product_description: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Product Name is Required"),
    product_price: Yup.number().required("Price is Required"),
    product_type: Yup.mixed(),
  });

  const editProductForm = useFormik({
    initialValues: editProductInitialValues,
    validationSchema: validateEditProduct,
    enableReinitialize: true,
    onSubmit: (values: ProductInfo) => {
      if (
        values.product_name === editProductData?.name &&
        values.product_description === editProductData?.description &&
        values.product_price === editProductData?.price
      ) {
        setEditProductErrorMessage("No Changes have been made");
        setEditProductError(true);
        return;
      } else {
        setEditProductErrorMessage("");
        setEditProductError(false);
      }

      const editProductPayload = {
        product_index: editProductData?.product_index,
        name: values?.product_name,
        price: values?.product_price,
        description: values.product_description,
      };

      dispatch(editProduct({ editProductPayload })).then((result: any) => {
        if (result.payload.result === true) {
          handleEditProductClose(result.payload.message);
        } else {
          setEditProductError(true);
          setEditProductErrorMessage(result.payload.message);
        }
      });
    },
  });

  const initialEditProduct = () => {
    if (editProductForm && editProductData) {
      setEditProductError(false);
      setEditProductErrorMessage("");
      setProductTypeError(false);
      setProductTypeErrorMessage("");

      editProductForm.setFieldValue(
        "product_description",
        editProductData?.description
      );
      editProductForm.setFieldValue("product_name", editProductData?.name);
      editProductForm.setFieldValue("product_price", editProductData?.price);
      editProductForm.setFieldValue("product_type", {
        label: editProductData?.product_type,
        value: editProductData?.product_type,
      });
    }
  };

  useEffect(() => {
    initialEditProduct();
  }, [editProductData]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleCloseEditProductDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Edit Product
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseEditProductDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addProductLoading} />
      <form onSubmit={editProductForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Product Name"
                  inputProps={{}}
                  label="Product Name"
                  id="product_name"
                  name="product_name"
                  variant="outlined"
                  fullWidth
                  value={editProductForm.values.product_name}
                  defaultValue={
                    editProductData?.name ? editProductData?.name : ""
                  }
                  error={
                    editProductForm.touched.product_name &&
                    Boolean(editProductForm.errors.product_name)
                  }
                  helperText={editProductForm.errors.product_name}
                  onChange={editProductForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Product Description"
                  label="Product Description"
                  id="product_description"
                  name="product_description"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={editProductForm.values.product_description}
                  defaultValue={
                    editProductData?.description
                      ? editProductData?.description
                      : ""
                  }
                  error={
                    editProductForm.touched.product_description &&
                    Boolean(editProductForm.errors.product_description)
                  }
                  helperText={editProductForm.errors.product_description}
                  onChange={editProductForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Product Price"
                  label="Product Price"
                  id="product_price"
                  name="product_price"
                  variant="outlined"
                  inputProps={{ type: "number" }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={editProductForm.values.product_price}
                  defaultValue={
                    editProductData?.price ? editProductData?.price : ""
                  }
                  error={
                    editProductForm.touched.product_price &&
                    Boolean(editProductForm.errors.product_price)
                  }
                  helperText={editProductForm.errors.product_price}
                  onChange={editProductForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <Autocomplete
                  id="product_type"
                  disabled
                  value={editProductForm.values.product_type}
                  defaultValue={
                    editProductData?.product_type
                      ? {
                          label: editProductData?.product_type,
                          value: editProductData?.product_type,
                        }
                      : undefined
                  }
                  onChange={(_: SyntheticEvent, newValue: any | null) => {
                    if (newValue) {
                      editProductForm.setFieldValue("programs", newValue);
                    } else {
                      editProductForm.setFieldValue("programs", undefined);
                    }
                  }}
                  options={Product_Type_Option}
                  getOptionLabel={(option: any) => option?.label}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Programs"
                      label={"Programs"}
                      error={productTypeError}
                      helperText={productTypeErrorMessage}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {editProductError && (
              <Grid
                container
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ color: "red" }}>
                  {editProductErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid" }}>
          <Button
            variant="outlined"
            onClick={() => handleCloseEditProductDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProductDialog;
