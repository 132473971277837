import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/geo.jpg";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const GeographicMarket = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>

          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              Do You Know Your Business’ Geographic Market?
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              For the majority of companies, there is a variety of factors that
              determine whether a customer will work with you or not. Many of
              those factors you can control, like the quality of your products,
              availability of inventory, and customer service, but there are
              some factors that aren’t in your control. One of those factors is
              your company’s geographic market.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              What is geographic market?
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Your business’ geographic market measures the number of miles that
              customers are willing to travel in order to work with you. If
              you’re beyond that maximum distance, then that customer is
              extremely unlikely to do business with you. The geographic market
              for any particular business will vary within different industries,
              markets, and regions. However, the majority of companies,
              especially brick and mortar ones, have a specific geographic
              market.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              To learn what your business’ geographic market is, you have to
              understand where your customers are coming from. Once you know
              where they are coming from, you can discover patterns and see
              whether distance is a factor and how important of a factor it is
              for developing your customer base.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Why geographic market matters for your business
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              If distance is an important factor for your company, it will be
              difficult or impossible to keep customers who are beyond that
              range. For example, the majority of heavy equipment dealers have a
              service area with a 60 mile radius at the most. The reason for
              this maximum distance is that beyond that range the dealer won’t
              be able to travel quickly enough to a customer who requires
              emergency equipment service.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              When you struggle to gain and retain customers beyond a specific
              range or outside of a particular geographic market, it’s not worth
              trying to sell your products and services to customers who aren’t
              located in that market. You’ll be spinning your wheels and wasting
              your marketing resources on them. For that reason, it’s crucial to
              understand how far the reach is for your specific business.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Use your geographic market to focus your strategy
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Although distance from a customer is not something you can
              typically control, you can use the knowledge of who is more likely
              to work with you than others to your advantage. By understanding
              your geographic market, you can focus your marketing within that
              range and only target prospects who have a lot more potential for
              your business. In the long run, this targeting will save you
              money, help you tailor your marketing and sales messaging, and
              become highly skilled at serving the customers in your specific
              area. All of these things are conducive to long term growth.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              To understand your company’s geographic market, it’s important to
              conduct a Zintoro market analysis of potential customers to
              determine how large your potential market is and exactly what the
              distance is for your maximum reach. This analysis will help guide
              your business strategy and keep you from wasting resources.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              What is your geographic market? Do you know how to identify
              prospects within that market, so you can convert them to
              customers?
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px " }}
            >
              Get help identifying who your best target customers are.
            </Typography>

            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/contactus"}
              >
                Contact Zintoro for a market analysis today!
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GeographicMarket;
