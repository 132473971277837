import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import geo from "./../../assets/geo.jpg";
import revenue from "./../../assets/revenue.jpg";
import industry from "./../../assets/industry.jpg";
import industry_type from "./../../assets/types.jpg";
import revenue_goals from "./../../assets/revenuegoals.jpg";
import banner from "./../../assets/banner_1.jpg";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const OurBlog = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 5% 10%",
        }}
      >
        <Grid container item xs={12}>
          <Grid container item xs={7} style={{}}>
            <Typography
              variant="h4"
              sx={{ color: "#3aae4a", fontWeight: "bold" }}
            >
              How to Improve Customer Retention
            </Typography>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography>
                Customer retention is crucial for the success of every company.
                It describes the perceitemntage of customers who purchased
                within the last 12 months who also purchased within the prior 12
                months—13 to 24 months ago. Poor customer retention means that
                you aren’t keeping customers over the long term and people are
                not committed to working […]
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${geo})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            ></Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Do You Know Your Business’ Geographic Market?
              </Typography>
              <Typography>
                For the majority of companies, there is a variety of factors
                that determine whether a customer will work with you or not.
                Many of those factors you can control, like the quality of your
                products, availability of inventory, and customer service, but
                there are some factors that aren’t in your control. One of those
                factors […]
              </Typography>
              <Grid item xs={10} style={{ margin: "20px 0px 20px 0px" }}>
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                  }}
                  to={"/ourblog/geographicmarket"}
                >
                  Read More
                </NavLink>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${revenue})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            ></Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Achieve Your Revenue Goals: The First Step Is Understanding
                Where You Are Now
              </Typography>
              <Typography>
                Revenue and transaction trends identify your company’s sales
                patterns over the prior twelve month period. Recognizing these
                patterns and what activities lead to growth is a critical step
                in making the right decisions for your company. Understanding
                your company’s revenue analytics and trends will help you answer
                the following key questions How to meet your […]
              </Typography>
              <Grid item xs={10} style={{ margin: "20px 0px 20px 0px" }}>
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                  }}
                  to={"/ourblog/revenueanalyticsgoals"}
                >
                  Read More
                </NavLink>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${industry})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            ></Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                What Is an Industry Analysis and Why Your Business Needs One
              </Typography>
              <Typography>
                As a business, you want to reach and sell to as many customers
                as possible. The problem is that it takes resources to connect
                with prospects and potential customers. Your company only has a
                limited amount of those resources, which means you have to make
                decisions about who to target with your marketing and sales […]
              </Typography>
              <Grid item xs={10} style={{ margin: "20px 0px 20px 0px" }}>
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                  }}
                  to={"/ourblog/industryanalysis"}
                >
                  Read More
                </NavLink>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${industry_type})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            ></Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Know the Types of Purchases Your Customers Are Making
              </Typography>
              <Typography>
                Business analytics reporting is crucial for gaining a better
                understanding of your business and your customers. One of the
                most important key metrics that we measure is called types of
                purchases. By analyzing what your customers are buying, we can
                determine which products they purchase most frequently, how
                often they buy them, and how your […]
              </Typography>
              <Grid item xs={10} style={{ margin: "20px 0px 20px 0px" }}>
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                  }}
                  to={"/ourblog/understandpurchases"}
                >
                  Read More
                </NavLink>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${revenue_goals})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            ></Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                How to Reach Your Revenue Goals
              </Typography>
              <Typography>
                Almost every business sets revenue goals for the year. Most will
                check their progress towards those goals by looking at how much
                revenue they’ve brought in to date and then comparing to
                previous years. But what a lot of businesses don’t do or don’t
                know how to do is look at why their revenue is […]
              </Typography>
              <Grid item xs={10} style={{ margin: "20px 0px 20px 0px" }}>
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                  }}
                  to={"/ourblog/revenuegoals"}
                >
                  Read More
                </NavLink>
              </Grid>
            </Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Understand the metrics that matter
              </Typography>
              <Typography>
                The first step toward growing your company and exceeding your
                revenue goals is understanding where the business is now with
                key metrics. Our blog reviews the most important business
                metrics and explains what they are, how to monitor them, and how
                to improve them. These metrics include: customer retention,
                purchase frequency, identifying at risk customers, and more. You
                will have a step by step guide on how to increase your growth as
                easily as possible.
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${banner})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "300px",
              }}
            ></Grid>
            <Grid container item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Measuring Customer Retention – What a 91% Retention Rate Really
                Means
              </Typography>
              <Typography>
                A business’ retention measures the percentage of customers that
                purchased within the last 12 months that also purchased within
                the prior 12 months. It is one of the most critical metrics to
                measure for any company. If you don’t have a solid business
                retention, then customers aren’t committed to working with you
                over the long term, and you must consistently devote time,
                money, and resources to find new customers.
              </Typography>
              <Grid item xs={10} style={{ margin: "20px 0px 20px 0px" }}>
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                  }}
                  to={"/ourblog/customerretention"}
                >
                  Read More
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={5} style={{}}>
            <DetailsLinks />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurBlog;
