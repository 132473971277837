import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import how_it_works from "./../../assets/how_it_works_banner.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const HowItWorks = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${how_it_works})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            How It Works
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 2% 10%",
        }}
      >
        <Typography variant="h4" sx={{ color: "#3aae4a", fontWeight: "bold" }}>
          Our business analytics reports provide valuable insights
        </Typography>
        <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem sx={{ display: "list-item" }}>
            We analyze three years of invoices
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Then, identify trends and metrics to focus on for improvement
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Specific changes are recommended to meet your revenue goals
          </ListItem>
        </List>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            The Zintoro process begins with looking at what you’re doing now and
            predicting how those trends will impact your business, and how you
            make changes to improve trends. Zintoro tracks key business metrics
            and gives you access to real time dashboards where you can compare
            your numbers to those of businesses similar to yours and understand
            how to achieve predictable growth.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            First, we determine where your business stands now
          </Typography>
          <Typography>
            You’ll provide us with three years of invoices, so we can load them
            into our proprietary portal and generate the analytics reports for
            your business. Then, we compare your numbers to businesses just like
            yours, to see how you compare. Our financial experts will walk you
            through the numbers and explain trends that are happening with your
            business that you may not be aware of.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Next, we tell you how to improve
          </Typography>
          <Typography>
            Each month, we will update the database with your invoices for the
            previous month to show any changes in trends. On a monthly call, our
            experts will measure your progress against projections, explain all
            the numbers and what they mean, and provide specifics on what to
            change to meet your revenue goals. We’ll identify realistic growth
            opportunities and recommend marketing and sales strategies that will
            help you achieve these goals.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            The results
          </Typography>
          <Typography>
            The business analytics reporting and insights that Zintoro provides
            remove the guesswork from your company’s strategy. The accuracy of
            our forecasts is over 95%, allowing you to see the future for your
            business if you keep doing things the same, or if you implement the
            changes we suggest.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography sx={{}}>
            If you want to meet and exceed your revenue goals,{" "}
            <NavLink
              style={{
                textDecoration: "none",
                color: "#00467f",
                fontWeight: "bold",
              }}
              to={"/contactus"}
            >
              schedule a consultation today
            </NavLink>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowItWorks;
