import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/banner_1.jpg";
import our_clients_2 from "./../../assets/post_content1.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useAppSelector } from "../../hooks/hooks";
const CustomerRetention = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      {" "}
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>
          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              Measuring Customer Retention – What a 91% Retention Rate Really
              Means
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              A business’ retention measures the percentage of customers that
              purchased within the last 12 months that also purchased within the
              prior 12 months. It is one of the most critical metrics to measure
              for any company. If you don’t have a solid business retention,
              then customers aren’t committed to working with you over the long
              term, and you must consistently devote time, money, and resources
              to find new customers.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Why long term business retention is so important
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Long term retention is critical for the profitability of your
              business. As an example, for heavy equipment dealers, the revenue
              that each customer generates skyrockets when they move from year
              two to year three as a customer for a dealer. Customers purchase
              2.9X more equipment, 9.1X more rentals, 4.1X more service, and
              5.6X more parts in the third year as a customer, compared to the
              second year.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              What that means is the longer a customer works with you, the more
              valuable they become for your business. Plus, losing the income
              from an existing customer is &gt;10 times the value of a new
              customer. It will take 3 to 5 years to replace the income produced
              from an existing customer with income from a new customer. That
              makes it extremely important to hold on to your current customers.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              This metric is crucial for profitability in both the short and
              long term. The question is, how do you go about measuring customer
              retention?
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Measuring customer retention for your business
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/ourblog/whereyouare"}
              >
                Measuring customer retention
              </NavLink>{" "}
              means looking at how many of your purchases came from existing and
              new customers over the past 12 months. Here’s how it is
              calculated:
            </Typography>
            <Grid
              item
              xs={7.9}
              sx={{
                backgroundImage: `url(${our_clients_2})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                color: "#fff",
                backgroundColor: "#fff",
                height: "275px",
                margin: "20px 0px 10px 0px",
              }}
            ></Grid>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Measuring customer retention using the above numbers would give
              you a rate of 91.7%.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              What a 91.7% business retention rate really means
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Although a customer retention rate of 91.7% may sound great, what
              it actually indicates is that you’re losing 8.3% of your customers
              each and every month. Losing 8.3% every month equals a turnover in
              customers of nearly 100% in one year. That’s your entire existing
              customer base!
            </Typography>
            <Typography sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}>
              How to improve your business retention
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              The simplest method for{" "}
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/ourblog/businessretention"}
              >
                improving retention
              </NavLink>{" "}
              is to find out why customers are leaving. The best way to do that
              is through customer satisfaction surveys conducted by an outside
              third party. Using a third party will elicit candid comments that
              customers may not want to share with an employee of your company,
              and the surveys will provide specific feedback, so you can solve
              any issues quickly.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              We recommend using Winsby to handle your customer satisfaction
              surveys. When they conduct surveys, we see an average increase in
              retention rates of 20% or more. They will work with you to develop
              an effective script, call your customers, find out any details
              related to negative responses, and share the survey results with
              you in real time. You’ll be able to solve issues fast, and your
              business will grow without any impediments!
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <a
                href="https://www.winsbyinc.com/customer-satisfaction-surveys.html"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                Click here for more details on Winsby customer satisfaction
                surveys.
              </a>
            </Typography>
            <Typography
              sx={{ margin: "10px 0px 0px 0px ", fontWeight: "bold" }}
            >
              If you have any questions about measuring customer retention or
              conducting customer satisfaction surveys to improve it,
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/contactus"}
              >
                contact our team today!
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CustomerRetention;
