import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utils/authMidleware";
import { backendURL, config, Backend_Urls } from "../../utils/constants";

const getSystemData = createAsyncThunk(
  "systemManagement/getSystemData",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.admin_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getUsers = createAsyncThunk(
  "systemManagement/getUsers",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.users_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addUser = createAsyncThunk(
  "systemManagement/addUser",
  async (
    { createUserPayload }: { createUserPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.users_url}`,
        createUserPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const editUser = createAsyncThunk(
  "systemManagement/editUser",
  async (
    { editUserPayload }: { editUserPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.update_user_url}`,
        editUserPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const disableUser = createAsyncThunk(
  "systemManagement/disableUser",
  async (
    { client_user_index }: { client_user_index: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.disable_user_url}`,
        { client_user_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const enableUser = createAsyncThunk(
  "systemManagement/enableUser",
  async (
    { client_user_index }: { client_user_index: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.enable_user_url}`,
        { client_user_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getClients = createAsyncThunk(
  "systemManagement/getClients",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.clients_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addClient = createAsyncThunk(
  "systemManagement/addClient",
  async (
    { createClientPayload }: { createClientPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.clients_url}`,
        createClientPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const editClient = createAsyncThunk(
  "systemManagement/editClient",
  async (
    { editClientPayload }: { editClientPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.update_client_url}`,
        editClientPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const disableClient = createAsyncThunk(
  "systemManagement/disableClient",
  async ({ client_index }: { client_index: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.disable_client_url}`,
        { client_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const enableClient = createAsyncThunk(
  "systemManagement/enableClient",
  async ({ client_index }: { client_index: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.enable_client_url}`,
        { client_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRoles = createAsyncThunk(
  "systemManagement/getRoles",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.roles_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProducts = createAsyncThunk(
  "systemManagement/getProducts",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.products_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addProduct = createAsyncThunk(
  "systemManagement/addProduct",
  async (
    { createProductPayload }: { createProductPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.products_url}`,
        createProductPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const editProduct = createAsyncThunk(
  "systemManagement/editProduct",
  async (
    { editProductPayload }: { editProductPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.update_product_url}`,
        editProductPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const deleteProduct = createAsyncThunk(
  "systemManagement/deleteProduct",
  async ({ product_index }: { product_index: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.delete_product_url}`,
        { product_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const enableProduct = createAsyncThunk(
  "systemManagement/enableProduct",
  async ({ product_index }: { product_index: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.enable_product_url}`,
        { product_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPrograms = createAsyncThunk(
  "systemManagement/getPrograms",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.programs_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPlans = createAsyncThunk(
  "systemManagement/getPlans",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.plans_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getClientPlan = createAsyncThunk(
  "systemManagement/getClientPlan",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.client_plan_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getClientType = createAsyncThunk(
  "systemManagement/getClientType",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.client_type_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllClientSalesmen = createAsyncThunk(
  "systemManagement/getAllClientSalesmen",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.all_client_salesmen}/${customer_id}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const activateSalesRep = createAsyncThunk(
  "systemManagement/activateSalesRep",
  async (
    { activateSalesRepPayload }: { activateSalesRepPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.activate_sales_rep}`,
        activateSalesRepPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const deactivateSalesRep = createAsyncThunk(
  "systemManagement/deactivateSalesRep",
  async (
    { deactivateSalesRepPayload }: { deactivateSalesRepPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.deactivate_sales_rep}`,
        deactivateSalesRepPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const attachProduct = createAsyncThunk(
  "systemManagement/attachProduct",
  async (
    { attachProductPayload }: { attachProductPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.attach_subscription_url}`,
        { ...attachProductPayload },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const paymentIntent = createAsyncThunk(
  "systemManagement/paymentIntent",
  async (
    { paymentIntentPayload }: { paymentIntentPayload: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.payment_intent}`,
        paymentIntentPayload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const cancelPaymentIntent = createAsyncThunk(
  "systemManagement/cancelPaymentIntent",
  async (
    { payment_intent_id }: { payment_intent_id: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.cancel_payment_intent}`,
        { payment_intent_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const paymentConfig = createAsyncThunk(
  "systemManagement/paymentConfig",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.payment_config_url}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getSystemData,
  addUser,
  editUser,
  getUsers,
  disableUser,
  enableUser,
  getClients,
  addClient,
  editClient,
  disableClient,
  enableClient,
  getRoles,
  getProducts,
  editProduct,
  deleteProduct,
  enableProduct,
  addProduct,
  getPrograms,
  getPlans,
  getClientPlan,
  getClientType,
  getAllClientSalesmen,
  activateSalesRep,
  deactivateSalesRep,
  attachProduct,
  paymentIntent,
  cancelPaymentIntent,
  paymentConfig,
};
