import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/types.jpg";

import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const UnderstandPurchases = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>

          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              Know the Types of Purchases Your Customers Are Making
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Business analytics reporting is crucial for gaining a better
              understanding of your business and your customers. One of the most
              important key metrics that we measure is called types of
              purchases. By analyzing what your customers are buying, we can
              determine which products they purchase most frequently, how often
              they buy them, and how your different branches or departments
              compare on sales of specific products.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Why types of purchases matter for your business
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              A successful business has to connect customers and potential
              customers with the products and services that they want and need
              to buy. By identifying which products you sell in the greatest
              quantities, you’re better able to focus your marketing and
              advertising strategies and make the most out of your limited
              resources and budget.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Products or services that are normally purchased more often will
              deliver a greater ROI for your marketing campaigns than less
              popular products would. People want to buy them; all they are
              waiting for is you to put the product in front of them to remind
              them to do so. Being aware of your customers’ most common types of
              purchases will help you avoid wasting money on advertising too
              much for products that are less popular and that yield a smaller
              ROI.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              How to take advantage of knowing types of purchases
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Once you determine your most frequently bought products, you gain
              a fuller picture of your business and what steps you should take
              to achieve growth.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Know where to place resources
            </Typography>
            <Typography sx={{}}>
              By understanding types of purchases, you will know which things to
              run promos on, which products to have front and center in your
              emails and on your website, and which products to focus on for any
              paid advertising. This metric will also tell you which products
              and services you should post most frequently about on social
              media.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Gain insights for product development
            </Typography>
            <Typography sx={{}}>
              You can use types of purchases to know what new products to
              develop. Leverage the knowledge of what sells the best to help
              create similar or complimentary products that are likely to sell
              equally as well.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Introduce customers to more products
            </Typography>
            <Typography sx={{}}>
              Using your knowledge of customer purchases, you can introduce them
              to additional things you offer. They will open your email, click
              on your ad, or come to your website for the more popular product
              that serves as the hook, but then they’ll see the other ones that
              you also have featured.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px " }}
            >
              Improve specific departments
            </Typography>
            <Typography sx={{}}>
              Knowing the types of purchases your customers make will help you
              understand your own business better. For example, you will be able
              to see which branches or departments sell more or less of specific
              products or services. If a product is selling great at every other
              branch but one, then there may be an issue specific to that
              branch.
            </Typography>

            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              At Zintoro, we’ll provide you with monthly business analytics
              reporting so you can understand key metrics like types of
              purchases, purchase frequency, customer retention, and many
              others. Then, our partner company Winsby can help you implement
              email, website, survey, and other marketing strategies to improve
              them all across the board.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default UnderstandPurchases;
