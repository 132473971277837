import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "./../../../assets/cropped-logo.png";
import logo_in from "./../../../assets/mainLogo.png";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { logout } from "../../../store/features/authentication/autehnticationSlice";
import {
  portalManagementReset,
  portalManagementSearchReset,
  updateMainView,
} from "../../../store/features/portalManagement/portalManagementSlice";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { systemManagementReset } from "../../../store/features/accountManagement/systemManagementSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );
  const { mainView, portalView } = useAppSelector(
    (state: any) => state.portalManagement
  );

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function userLogout() {
    dispatch(logoutUser({ user_index: userInfo.client_user_index }));
    dispatch(portalManagementReset());
    dispatch(systemManagementReset());
    dispatch(logout());
    navigate("/");
  }

  return (
    <Grid container item xs={12} sx={{ display: "flex", flexGrow: 1 }}>
      {loggedIn ? (
        <Grid
          container
          sx={{
            borderTop: "10px solid #38B54A",
            padding: "1% 3% 1% 2%",
            backgroundColor: "#004680",
          }}
        >
          <Grid
            container
            item
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "80px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${logo_in})`,
                backgroundRepeat: "no-repeat",
                color: "#fff",
                height: "100px",
              }}
            ></Grid>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "white",
              paddingLeft: "20px",
            }}
          >
            <Typography variant="h6">Hello {userInfo?.first_name}!</Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Welcome back to the dashboard
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!mainView && (
              <Grid
                item
                xs={7}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ height: "40px", color: "white", gap: 1 }}
                  onClick={() => dispatch(updateMainView())}
                >
                  <ExitToAppIcon /> PORTAL MANAGEMENT
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Grid
                    sx={{
                      display: "flex",
                    }}
                    gap={1}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <Avatar
                        style={{ border: "1ps solid blue" }}
                        alt={userInfo?.first_name}
                        src="/static/images/avatar/2.jpg"
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        color: "white",
                      }}
                    >
                      <Typography variant="h6">
                        {userInfo?.first_name} {userInfo?.last_name}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Member Since {2024}
                      </Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "80px", width: "300px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>
                {(userInfo.role_index === 100 ||
                  userInfo.role_index === 101 ||
                  userInfo.role_index === 102) && (
                  <MenuItem
                    onClick={() => {
                      navigate("/systemManagement");

                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">
                      System Management
                    </Typography>
                  </MenuItem>
                )}
                {/* <MenuItem
                  onClick={() => {
                    navigate("/userProfile");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">User Profile</Typography>
                </MenuItem> */}
                {/* <MenuItem onClick={() => navigate("/helpSupport")}>
                  <Typography textAlign="center">Help & Support</Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    userLogout();
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={{ display: "flex" }}>
          <Grid
            item
            xs={12}
            sx={{
              marginLeft: "10%",
              marginRight: "10%",
              display: "flex",
              alignItems: "center",
              padding: "30px 0",
              flexGrow: 1,
            }}
          >
            <Grid item sx={{ display: "flex", padding: "10px" }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href={"/"}
                sx={{
                  mr: 2,
                  display: "flex",
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <img
                  style={{ width: "204", height: "78" }}
                  src={logo}
                  alt={"Zintoro App"}
                />
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                padding: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "#626262",
                  margin: "0",
                  fontWeight: "600",
                }}
              >
                We monitor Key Metrics to Improve Results
              </Typography>
            </Grid>
            <Grid
              // container
              item
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                padding: "10px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#004680",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                onClick={() => navigate("/gettingstarted")}
              >
                Getting Started
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#004680",
                  color: "#fff",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar
                variant="dense"
                disableGutters
                sx={{
                  backgroundColor: "#004680",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  height: "10px",
                  flexGrow: 1,
                }}
              >
                <NavLink
                  to={"/whyzintoro"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  Why Zintoro
                </NavLink>
                <NavLink
                  to={"/howitworks"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  How It Works
                </NavLink>
                <NavLink
                  to={"/aboutus"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  About US
                </NavLink>
                <NavLink
                  to={"/whatyoulearn"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  What You Learn
                </NavLink>
                <NavLink
                  to={"/results"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  Results
                </NavLink>
                <NavLink
                  to={"/ourclients"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  Our Clients
                </NavLink>
                <NavLink
                  to={"/ourblog"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  Our Blog
                </NavLink>
                <NavLink
                  to={"/ourpartners"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  Our Partners
                </NavLink>
                <NavLink
                  to={"/contactus"}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: "#fff",
                      fontWeight: "bold",
                    };
                  }}
                >
                  Contact Us
                </NavLink>
              </Toolbar>
            </AppBar>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default Header;
