import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import about_us from "./../../assets/about_us_banner.jpg";
import jb from "./../../assets/sam.jpg";
import steve from "./../../assets/about_img1.jpg";
import debbie from "./../../assets/about_img2.jpg";
import { useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
const AboutUs = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${about_us})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            About Us
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 2% 10%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            padding: "0% 0% 2% 0%",
            color: "#3aae4a",
            fontWeight: "bold",
          }}
        >
          We help our clients see and realize their future
        </Typography>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Zintoro provides tools that empower leaders with actions that are
            AI-driven
          </Typography>
          <Typography>
            In today’s complex business world, data is the key to success.
            Zintoro is a game changing AI intelligence platform with clear and
            straightforward data analytics. These analytics empower businesses
            to make decisions based on an accurately forecasted future.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            Zintoro identifies what steps to take to optimize operations,
            marketing strategies, and overall performance by consolidating data
            from multiple sources and utilizing advanced AI-driven analytics. It
            breaks down data silos, integrating information from various
            platforms, ensuring a comprehensive view of your organization’s
            performance and expected future with a &gt;95% accuracy.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Now, you can be confident of your decisions, guided by Zintoro’s AI
            data analyses
          </Typography>
          <Typography>
            Data, Numbers, and statistics can be overwhelming. Zintoro
            simplifies the process with AI-driven reports that focus on the
            critical metrics needed to manage a business effectively. With this
            approach, companies can understand what is working, how to improve
            overall and at every location and in each department. The customers
            that are at risk of being lost are identified to focus on and
            retain.
          </Typography>
        </Grid>
        <Grid container item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Based in Chicago, our clients include manufacturers, distributors,
            and service businesses.
          </Typography>
        </Grid>
        <Typography
          variant="h4"
          sx={{
            padding: "0% 0% 2% 0%",
            color: "#3aae4a",
            fontWeight: "bold",
          }}
        >
          Our Founder
        </Typography>
        <Grid container item xs={8} sx={{ margin: "0px 0px 5px 0px" }}>
          <Typography>
            In 2009, Steve Clegg founded Zintoro B2B Analytics LLC. He had been
            involved with a variety of businesses and in a wide range of
            industries globally. After developing accurate forecasts and
            predictable ways of generating growth, he wanted to share his
            knowledge and experience with other companies to help them manage
            their future and identify what is working and how they can improve
            their results.
          </Typography>
        </Grid>
        <Grid container item xs={10}>
          <Grid container item xs={12} sx={{ margin: "2% 0% 2% 0%" }}>
            <Grid item xs={2}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundImage: `url(${steve})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "#fff",
                  height: "100%",
                }}
              ></Grid>
            </Grid>
            <Grid item xs={8} sx={{ padding: "0px 0px 0px 20px" }}>
              <Grid container sx={{ margin: "0px 0px 10px 0px" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Steve Clegg
                </Typography>
                <Typography>
                  Steve had been with AEA Investors, Inc. and had served on the
                  boards of directors and in executive positions for companies
                  they purchased. The industries were diverse and included a
                  group of steel mills that were bought individually, then
                  combined, a chain of discount retail stores across the U.S., a
                  roofing materials manufacturer that expanded into
                  installation, a weight loss franchise that grew to over 2,400
                  locations in North America, a group of shoe stores that
                  tripled, a group of companies that produced custom
                  manufactured items according to customer specifications across
                  several locations with disparate capabilities, and more!
                </Typography>
              </Grid>
              <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
                <Typography sx={{}}>
                  Steve began his career in international finance, working for
                  WR Grace, then Avis. He earned his Bachelor of Science degree
                  from the United States Air Force Academy and a Master of
                  Business Administration from the University of Chicago.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ margin: "2% 0% 2% 0%" }}>
            <Grid item xs={2} sx={{}}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundImage: `url(${debbie})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "#fff",
                  height: "100%",
                }}
              ></Grid>
            </Grid>
            <Grid item xs={8} sx={{ padding: "0px 0px 0px 20px" }}>
              <Grid container>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Debbie Frakes
                </Typography>
                <Typography>
                  Debbie has started several businesses, and each has grown
                  rapidly. With her first venture, she purchased a service
                  franchise that expanded to seven locations within two years.
                  Another startup established an elder care management company
                  throughout a major metropolitan area. A successful catalog
                  operation and a market research company also preceded Winsby.
                </Typography>
              </Grid>
              <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
                <Typography sx={{}}>
                  Her first position outside of school was as an environmental
                  consultant in New York City. She earned a Bachelor of Arts in
                  environmental science from Barnard College, Columbia
                  University, and a Master of Business Administration in finance
                  and marketing from the Columbia University School of Business.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ margin: "2% 0% 2% 0%" }}>
            <Grid item xs={2}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundImage: `url(${jb})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "#fff",
                  height: "100%",
                }}
              ></Grid>
            </Grid>
            <Grid item xs={8} sx={{ padding: "0px 0px 0px 20px" }}>
              <Grid container sx={{ margin: "0px 0px 10px 0px" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Sammazo Plamin
                </Typography>
                <Typography>
                  Sammazo is a data driven decision scientist whose passion for
                  analytics and data transformation has made a significant
                  impact in the oil & gas, legal, medical, and marketing
                  industries. During his tenure as a Manager Data Scientist at
                  KPMG, Sammazo conceptualized and developed AI driven tools on
                  platforms like Azure, AWS, and GCP, focusing on predictive
                  analytics, chatbot development, and natural language
                  processing. From helping asset leaders in the Oil and Gas
                  sector make informed decisions, to fine tuning language models
                  to extract medical insights, his expertise has made him a
                  sought after professional in the field.
                </Typography>
              </Grid>
              <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
                <Typography sx={{}}>
                  Sammazo started his journey as a Reservoir Engineer at Swift
                  Energy Operating LLC. He holds a Bachelor of Science degree
                  from Texas A&M University, where he was distinguished with the
                  Engineering Honors and Research Honors Distinction, and a
                  Master of Science from the University of Chicago, with his
                  capstone project gaining the spotlight as the “Best in Show.”
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
