import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import home_banner from "./../../assets/banner_1-1.jpg";
import document_image from "./../../assets/lg_1.png";
import graph_image from "./../../assets/lg_2.png";
import lightbulb_image from "./../../assets/lg_3.png";
import itentify_image from "./../../assets/lg_4.png";
import Forecast_image from "./../../assets/lg_5-1.png";
import how_improve_image from "./../../assets/home_improve_back.jpg";
import how_improve_image_1 from "./../../assets/imp_1.png";

import client_1 from "./../../assets/client_1.jpg";
import client_2 from "./../../assets/client_2.jpg";
import client_3 from "./../../assets/client_3.jpg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";

const Hone = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${home_banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 600,
            }}
          >
            Zintoro Tracks Metrics <br /> Essential for Growth
          </Typography>
        </Grid>
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            And provides accurate revenue <br /> projections
          </Typography>
        </Grid>
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            Exceed revenue goals
            <br />
            Increase customer retention
            <br />
            Reach new markets
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#ffeca3",
        }}
      >
        <Grid container item xs={8} sx={{ padding: "3% 0px 3% 0px" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 10px 0px",
            }}
          >
            <Grid
              container
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Zintoro Identifies Where You Are Now and Shows You How to
                Improve
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 10px 0px",
            }}
          >
            <Grid
              container
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  backgroundImage: `url(${document_image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "121px",
                  height: "106px",
                }}
              ></Grid>
              <Grid
                sx={{
                  textAlign: "center",
                  padding: "2% 20% 2% 20%",
                }}
              >
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  DOCUMENT
                </span>{" "}
                where you are now
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  backgroundImage: `url(${graph_image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "121px",
                  height: "106px",
                }}
              ></Grid>
              <Grid
                sx={{
                  textAlign: "center",
                  padding: "2% 20% 2% 20%",
                }}
              >
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  COMPARE
                </span>{" "}
                your metrics to your competitors
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  backgroundImage: `url(${lightbulb_image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "121px",
                  height: "106px",
                }}
              ></Grid>
              <Grid
                sx={{
                  textAlign: "center",
                  padding: "2% 10% 2% 10%",
                }}
              >
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  UNDERSTAND
                </span>{" "}
                how to achieve predictable growth
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  backgroundImage: `url(${itentify_image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "121px",
                  height: "106px",
                }}
              ></Grid>
              <Grid
                sx={{
                  textAlign: "center",
                  padding: "2% 15% 2% 15%",
                }}
              >
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  IDENTIFY
                </span>{" "}
                the resources you’ll need
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 10px 0px",
            }}
          >
            <Grid
              container
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  backgroundImage: `url(${Forecast_image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "132px",
                  height: "80px",
                }}
              ></Grid>
              <Grid
                sx={{
                  textAlign: "center",
                  padding: "2% 15% 2% 15%",
                }}
              >
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  FORECAST
                </span>{" "}
                your growth with certainty
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 10,
              margin: "10px 0px 10px 0px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#004680",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Schedule A Call
            </Button>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#004680",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Get Started Now
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(${how_improve_image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          // width: "172px",
          // height: "261px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{ color: "#00467f", fontWeight: "bold" }}
          >
            How Can You Improve?
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            marginTop: "60px",
            padding: "50px 100px",
          }}
        >
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4" sx={{ color: "#00467f" }}>
              Zintoro tells you{" "}
              <span style={{ fontWeight: "bold" }}>exactly where to focus</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={10}>
              <Typography
                variant="h5"
                sx={{ textAlign: "center", color: "#00467f" }}
              >
                Invest your time and resources back into your business with our
                key metrics that measure your results and identify realistic
                growth opportunities. When you focus on the gaps, the results
                are consistently impressive.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container item xs={10}>
              <Grid
                item
                xs={3}
                sx={{
                  backgroundImage: `url(${how_improve_image_1})`,
                  backgroundRepeat: "no-repeat",
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                  // padding: "5% 10% 5% 10%",
                  color: "#fff",
                  width: "172px",
                  height: "261px",
                }}
              ></Grid>
              <Grid container item xs={8} sx={{ color: "#00467f" }}>
                <Grid item xs={12}>
                  <Typography>Schedule an Assessment to Understand:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <Typography>
                        Core key metrics reports—Monthly updates show your
                        progress
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Market industry analysis—Identify your perfect prospects
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Website and online performance assessment—See where you
                        can improve
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Customer and employee online reputation—Track changes
                        online
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Monthly conference calls with an in-depth review of your
                        business
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        Specific recommendations are provided to focus on
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", gap: 10 }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#004680",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Learn More about the Assessment
            </Button>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#004680",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Schedule A Call
            </Button>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#004680",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Get Started Now
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{ padding: "5% 10% 5% 10%" }}>
        <Grid item xs={12} sx={{ margin: "0px 0px 2% 0px" }}>
          <Typography
            variant="h3"
            sx={{ color: "#00467f", fontWeight: "bold" }}
          >
            Our Clients
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                backgroundImage: `url(${client_1})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "5% 10% 5% 10%",
                color: "#fff",
                width: "350px",
                height: "205px",
              }}
            ></Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Distributors
              </Typography>
              <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                for any products and services
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                backgroundImage: `url(${client_2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "5% 10% 5% 10%",
                color: "#fff",
                width: "350px",
                height: "205px",
              }}
            ></Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Manufacturers
              </Typography>
              <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                and their distributors
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={10}
              sx={{
                backgroundImage: `url(${client_3})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "5% 10% 5% 10%",
                color: "#fff",
                width: "350px",
                height: "205px",
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Any Company
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                that wants to learn how to grow profitably
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "2%",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#004680",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Contact Us Now
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Hone;
