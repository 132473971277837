import {
  Button,
  Divider,
  Grid,
  Typography,
  Snackbar,
  Box,
  MenuItem,
  ListItemIcon,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/reusableComponents/spinner/spinner";
import { getClientsInfo } from "../../../store/thunks/portalManagementThunk";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { portalManagementReset } from "../../../store/features/portalManagement/portalManagementSlice";
import { systemManagementReset } from "../../../store/features/accountManagement/systemManagementSlice";
import { logout } from "../../../store/features/authentication/autehnticationSlice";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  activateSalesRep,
  deactivateSalesRep,
  getAllClientSalesmen,
} from "../../../store/thunks/systemManagementThunk";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import LockResetIcon from "@mui/icons-material/LockReset";
import EnableSaleRepDialog from "./enableSaleRepDialog/enableSaleRepDialog";

const SalesRepManagement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { portalClientsLoading, portalClients } = useAppSelector(
    (state: any) => state.portalManagement
  );
  const {
    getAllClientSalesmenLoading,
    allClientSalesMen,
    activateSalesRepLoading,
    deactivateSalesRepLoading,
  } = useAppSelector((state: any) => state.systemManagement);
  const [selectedClientSalerep, setSelectedClientSalerep] = useState<any>(null);
  const [selectedSalesRepData, setSelectedSalesRepData] = useState<any>(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openEnableSaleRepDialog, setOpenEnableSaleRepDialog] = useState(false);

  const handleSelectClientSalesRep = async (newValue: any) => {
    setSelectedClientSalerep(newValue);
    if (newValue) {
      await dispatch(
        getAllClientSalesmen({
          customer_id: newValue?.customer_id,
        })
      ).then((getAllClientSalesmenResult: any) => {
        if (getAllClientSalesmenResult?.error) {
          if (getAllClientSalesmenResult?.error?.message === "Rejected") {
            dispatch(logoutUser({ user_index: userInfo?.client_user_index }));
            dispatch(portalManagementReset());
            dispatch(systemManagementReset());
            dispatch(logout());
            navigate("/login");
          }
        }
      });
    }
  };

  useEffect(() => {
    if (!portalClients || portalClients?.length === 0) {
      dispatch(getClientsInfo({})).then((getClientsInfoResult: any) => {
        if (getClientsInfoResult?.error) {
          if (getClientsInfoResult?.error?.message === "Rejected") {
            dispatch(logoutUser({ user_index: userInfo?.client_user_index }));
            dispatch(portalManagementReset());
            dispatch(systemManagementReset());
            dispatch(logout());
            navigate("/login");
          }
        }
      });
    }
  }, []);

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      let response: any = {};
      await dispatch(
        getAllClientSalesmen({
          customer_id: selectedClientSalerep?.customer_id,
        })
      );
      return response;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const salerepColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "SALESMAN", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Sale Rep",
          },
          {
            accessorFn: (row: any) =>
              `${row.first_name ? row.first_name : ""} ${
                row.last_name ? row.last_name : ""
              }`, //accessorFn used to join multiple data into a single cell
            id: "name", //id is still required when using accessorFn instead of accessorKey
            header: "Full Name",
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          {
            accessorKey: "user_name",
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Email",
          },
          {
            accessorKey: "status",
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Sale Rep Status",
          },
        ],
      },
    ],
    []
  );

  const salerepTable = useMaterialReactTable({
    columns: salerepColumns,
    data: allClientSalesMen ? allClientSalesMen : [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: getAllClientSalesmenLoading, //cell skeletons and loading overlay
      // showProgressBars: getUserLoading, //progress bars while refetching
      // isSaving: getUserLoading, //progress bars and save button spinners
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageIndex: 0, pageSize: 10 },
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      !row.original?.user_name && (
        <MenuItem
          key={2}
          onClick={() => {
            setSelectedSalesRepData(row.original);
            setOpenEnableSaleRepDialog(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Assign Sales Rep
        </MenuItem>
      ),
      row.original?.status === "Active" && (
        <MenuItem
          key={2}
          onClick={() => {
            handleDeactivateSalesRep(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Disable Sales Rep
        </MenuItem>
      ),
      row.original?.status === "Inactive" && (
        <MenuItem
          key={2}
          onClick={() => {
            handleActivateSalesRep(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Activate Sales Rep
        </MenuItem>
      ),
    ],
  });

  const handleCloseEnableSaleRepDialog = (message: string) => {
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setOpenEnableSaleRepDialog(false);
  };

  const handleDeactivateSalesRep = (salesRepData: any) => {
    dispatch(
      deactivateSalesRep({
        deactivateSalesRepPayload: {
          client_user_index: salesRepData?.client_user_index
            ? salesRepData?.client_user_index
            : "",
          sales_rep_name: salesRepData?.SALESMAN,
          customer_id: salesRepData?.customer_id,
        },
      })
    ).then((deactivateSalesRepResult: any) => {
      setSnackbarMessage(deactivateSalesRepResult?.payload?.message);
      setOpenSnackbar(true);
      refetch();
    });
  };

  const handleActivateSalesRep = (salesRepData: any) => {
    dispatch(
      activateSalesRep({
        activateSalesRepPayload: {
          client_user_index: salesRepData?.client_user_index
            ? salesRepData?.client_user_index
            : "",
          sales_rep_name: salesRepData?.SALESMAN,
          customer_id: salesRepData?.customer_id,
        },
      })
    ).then((activateSalesRepResult: any) => {
      setSnackbarMessage(activateSalesRepResult?.payload?.message);
      setOpenSnackbar(true);
      refetch();
    });
  };

  return (
    <Grid>
      <Spinner loading={portalClientsLoading} />
      <Spinner loading={activateSalesRepLoading} />
      <Spinner loading={deactivateSalesRepLoading} />
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography variant="h4" sx={{ color: "#004680", fontWeight: "bold" }}>
          Sales Rep Management
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 20px 10px 20px !important",
        }}
      >
        <Grid item xs={6}>
          <Autocomplete
            id="clients"
            value={selectedClientSalerep}
            onChange={(_: SyntheticEvent, newValue: string | null) => {
              handleSelectClientSalesRep(newValue);
            }}
            options={portalClients}
            getOptionLabel={(option) => option.customer_name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Clients"
                label="Select Client"
              />
            )}
          />
        </Grid>
        {selectedClientSalerep && (
          <Grid container item xs={12} sx={{ padding: "20px 0px 20px 0px" }}>
            <TableTemplate tableData={salerepTable} />
          </Grid>
        )}
      </Grid>
      <EnableSaleRepDialog
        open={openEnableSaleRepDialog}
        handleClose={handleCloseEnableSaleRepDialog}
        selectedSalesRepData={selectedSalesRepData}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </Grid>
  );
};
export default SalesRepManagement;
