import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import what_you_learn from "./../../assets/what_you_learn.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const WhatYouLearn = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${what_you_learn})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            What You’ll Learn
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            About Your Company
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 2% 10%",
        }}
      >
        <Typography variant="h4" sx={{ color: "#3aae4a", fontWeight: "bold" }}>
          Understand how to reach your full potential
        </Typography>
        <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem sx={{ display: "list-item" }}>
            The Initial assessment uses critical business metrics to show you
            where you are now and forecast your next 12 months’ of customers,
            transactions, and revenue by department and location with a &gt;95%
            accuracy.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            See what changes you should make to reach your revenue and profit
            goals
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Know what customers are at risk of being lost
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Receive a clear management roadmap to profitably build your business
          </ListItem>
        </List>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            The Zintoro program looks at over three years of invoices and
            focuses on the most important analytics to show you the patterns for
            your company during that time period. It then identifies changes
            necessary to reverse or build on existing patterns and accurately
            forecasts your next 12 months.
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h5"
            sx={{ color: "#3aae4a", fontWeight: "bold" }}
          >
            Find out where to focus for real progress toward your revenue goal
          </Typography>
          <Typography>
            The Zintoro analysis forecasts your future if you continue doing
            what you have done in the past. It then teaches you how to leverage
            your existing employees and resources for sustainable and
            predictable growth. Our team also offers guidance on what additional
            steps and tools are required for you to meet your plans and goals
            effectively. From staff productivity to customer engagement, our
            assessment is like an x-ray for your business, clearly showing
            what’s vital and what areas need more support. You’ll learn where
            your company stands now and what you must do to achieve higher
            growth.
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h5"
            sx={{ color: "#3aae4a", fontWeight: "bold" }}
          >
            The initial assessment shows where your business is now
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#3aae4a", fontStyle: "italic" }}
          >
            Your data is stored on a secure, password-protected portal.
          </Typography>
        </Grid>
        <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
            Core key business metrics you learn about your company:
          </ListItem>
          <List sx={{ listStyleType: "disc", paddingLeft: "40px" }}>
            <ListItem sx={{ display: "list-item" }}>
              What customers are at risk of being lost
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Your actual and forecast for customers, transactions, revenues,
              and gross margin by department and branch
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              What customers are new, what customers have been lost, and what is
              your actual customer retention rate
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              The ROI on your sales and marketing programs
            </ListItem>
          </List>
          <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
            Our market industry analysis identifies potential business in your
            market, including geographic and industry constraints, so you know
            who to target
          </ListItem>
          <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
            We provide recommendations so you understand where to improve growth
            and your overall business performance
          </ListItem>
        </List>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            What you learn through Zintoro shows you where to place your
            resources to achieve your revenue goal.
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography sx={{}}>
            <NavLink
              style={{
                textDecoration: "none",
                color: "#00467f",
                fontWeight: "bold",
              }}
              to={"/contactus"}
            >
              Schedule a consultation to gain critical insights into your
              company.
            </NavLink>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhatYouLearn;
