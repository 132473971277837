import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { UserInfo } from "../../../../types/formTypes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { editUser } from "../../../../store/thunks/systemManagementThunk";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import {
  getClientsPrograms,
  getClientsSalesRep,
} from "../../../../store/thunks/portalManagementThunk";
import { compareTwoObjectArray } from "../../../../utils/utils";
interface EditUserDialogProps {
  open: any;
  handleEditClose: any;
  editUserData: any;
}

const EditUserDialog = (props: EditUserDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleEditClose, editUserData } = props;

  const { allClients, allRoles, clientType, addUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const [nameChange, setNameChange] = useState(false);
  const [roleChange, setRoleChange] = useState(false);

  const [showEditUserClient, setShowEditUserClient] = useState(false);
  const [showEditUserClients, setShowEditUserClients] = useState(false);

  const [showEditUserClientType, setShowEditUserClientType] = useState(false);
  const [showEditUserSaleRep, setShowEditUserSaleRep] = useState(false);

  const [clientTypeListEditUser, setClientTypeListEditUser] = useState([]);
  const [saleRepListEditUser, setSaleRepListEditUser] = useState([]);

  const [roleErrorEditUser, setRoleErrorEditUser] = useState(false);
  const [roleErrorMessageEditUser, setRoleErrorMessageEditUser] = useState("");

  const [clientErrorEditUser, setClientErrorEditUser] = useState(false);
  const [clientErrorMessageEditUser, setClientErrorMessageEditUser] =
    useState("");

  const [clientTypeErrorEditUser, setClientTypeErrorEditUser] = useState(false);
  const [clientTypeErrorMessageEditUser, setClientTypeErrorMessageEditUser] =
    useState("");

  const [saleRepErrorEditUser, setSaleRepErrorEditUser] = useState(false);
  const [saleRepErrorMessageEditUser, setSaleRepErrorMessageEditUser] =
    useState("");

  const [editUserError, setEditUserError] = useState(false);
  const [editUserErrorMessage, setEditUserErrorMessage] = useState("");

  let loginInitialValues: UserInfo = {
    email: "",
    first_name: "",
    last_name: "",
    user_role: undefined,
    client: undefined,
    clients: undefined,
    client_type: undefined,
    sale_rep: undefined,
  };

  const validateEditUser = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    first_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is Required"),
    last_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is Required"),
    user_role: Yup.mixed(),
    client: Yup.mixed(),
    clients: Yup.mixed(),
    client_type: Yup.mixed(),
    sale_rep: Yup.mixed(),
  });

  const editUserForm = useFormik({
    initialValues: loginInitialValues,
    validationSchema: validateEditUser,
    enableReinitialize: true,
    onSubmit: (values: UserInfo) => {
      let submitCheck = handleEditUserChangeValidate();
      if (!submitCheck) {
        setEditUserError(true);
        return;
      }
      let editUserPayload: any;
      setRoleErrorEditUser(false);
      setRoleErrorMessageEditUser("");
      setClientErrorEditUser(false);
      setClientErrorMessageEditUser("");
      setClientTypeErrorEditUser(false);
      setClientTypeErrorMessageEditUser("");
      setSaleRepErrorEditUser(false);
      setSaleRepErrorMessageEditUser("");
      setEditUserError(false);
      setEditUserErrorMessage("");

      let update_type = [];

      if (nameChange) {
        update_type.push("name");
        editUserPayload = {
          ...editUserPayload,
          current_first_name: editUserData?.first_name,
          current_last_name: editUserData?.last_name,
          new_first_name: values.first_name,
          new_last_name: values.last_name,
          update_type: update_type,
          user_index: editUserData?.client_user_index,
        };
      }
      if (roleChange) {
        update_type.push("role");
        if (values.user_role) {
          if (values.user_role?.role_index === 103) {
            if (values.client) {
              if (values.client_type) {
                if (values.client_type.client_type_index === 100) {
                  if (values.sale_rep) {
                    editUserPayload = {
                      ...editUserPayload,
                      user_index: editUserData?.client_user_index,
                      new_user_role_index: values.user_role.role_index,
                      current_update_user_role_index: editUserData?.role_index,
                      new_client_list: [values.client],
                      current_update_user_client_list:
                        editUserData?.client_list,
                      new_client_type_index:
                        values.client_type.client_type_index,
                      new_sale_rep: values.sale_rep.SALESMAN,
                      update_type: update_type,
                    };
                  } else {
                    setSaleRepErrorEditUser(true);
                    setSaleRepErrorMessageEditUser("Sale Rep is required");
                  }
                } else {
                  editUserPayload = {
                    ...editUserPayload,
                    user_index: editUserData?.client_user_index,
                    new_user_role_index: values.user_role.role_index,
                    current_update_user_role_index: editUserData?.role_index,
                    new_client_list: [values.client],
                    current_update_user_client_list: editUserData?.client_list,
                    new_client_type_index: values.client_type.client_type_index,
                    new_sale_rep: "",
                    update_type: update_type,
                  };
                }
              } else {
                setClientTypeErrorEditUser(true);
                setClientTypeErrorMessageEditUser("Client Type is required");
              }
            } else {
              setClientErrorEditUser(true);
              setClientErrorMessageEditUser("Client is requied");
              return;
            }
          } else if (
            values.user_role?.role_index === 101 ||
            values.user_role?.role_index === 102
          ) {
            if (values.clients) {
              editUserPayload = {
                ...editUserPayload,
                user_index: editUserData?.client_user_index,
                new_user_role_index: values.user_role.role_index,
                current_update_user_role_index: editUserData?.role_index,
                new_client_list: values.clients,
                current_update_user_client_list: editUserData?.client_list,
                update_type: update_type,
              };
            } else {
              setClientErrorEditUser(true);
              setClientErrorMessageEditUser("Client is requied");
              return;
            }
          } else {
            editUserPayload = {
              ...editUserPayload,
              user_index: editUserData?.client_user_index,
              new_user_role_index: values.user_role.role_index,
              current_update_user_role_index: editUserData?.role_index,
              update_type: update_type,
              current_update_user_client_list: editUserData?.client_list,
            };
          }
        } else {
          setRoleErrorEditUser(true);
          setRoleErrorMessageEditUser("User Role is required");
          return;
        }
      }
      if (nameChange || roleChange) {
        dispatch(editUser({ editUserPayload: editUserPayload })).then(
          (result: any) => {
            if (result.payload.result === true) {
              handleCloseEditUserDialog(result.payload.message);
            } else {
              setEditUserError(true);
              setEditUserErrorMessage(result.payload.message);
            }
          }
        );
      }
    },
  });

  const handleCloseEditUserDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setShowEditUserClient(false);
    setShowEditUserClients(false);
    setShowEditUserClientType(false);
    setShowEditUserSaleRep(false);
    setRoleErrorEditUser(false);
    setRoleErrorMessageEditUser("");
    setClientErrorEditUser(false);
    setClientErrorMessageEditUser("");
    setClientTypeErrorEditUser(false);
    setClientTypeErrorMessageEditUser("");
    setSaleRepErrorEditUser(false);
    setSaleRepErrorMessageEditUser("");
    setClientTypeListEditUser([]);
    setSaleRepListEditUser([]);
    handleEditClose(message);
    // editUserForm.resetForm();
  };

  const inititalEditUserSetup = () => {
    if (editUserForm) {
      setShowEditUserClient(false);
      setShowEditUserClients(false);
      setShowEditUserClientType(false);
      setShowEditUserSaleRep(false);
      setRoleErrorEditUser(false);
      setRoleErrorMessageEditUser("");
      setClientErrorEditUser(false);
      setClientErrorMessageEditUser("");
      setClientTypeErrorEditUser(false);
      setClientTypeErrorMessageEditUser("");
      setSaleRepErrorEditUser(false);
      setSaleRepErrorMessageEditUser("");

      editUserForm.setFieldValue("email", editUserData?.user_name);
      editUserForm.setFieldValue("first_name", editUserData?.first_name);
      editUserForm.setFieldValue("last_name", editUserData?.last_name);
      editUserForm.setFieldValue("user_role", {
        role: editUserData?.role,
        role_index: editUserData?.role_index,
        role_label: editUserData?.role_label,
      });
      if (editUserData?.role_index === 103) {
        setShowEditUserClient(true);
        editUserForm.setFieldValue("client", editUserData?.client_list[0]);
        dispatch(
          getClientsPrograms({
            client_index: editUserData?.client_list[0].client_index,
          })
        ).then((result: any) => {
          if (result.payload?.programs?.length > 0) {
            let analytic_check = false;
            result.payload.programs.forEach((program: any) => {
              if (program.program_index === 100) analytic_check = true;
            });
            if (analytic_check) {
              setClientTypeListEditUser(clientType);
            } else {
              let tempClientList = clientType
                ? clientType.filter(
                    (client_type: any) => client_type.client_type_index !== 100
                  )
                : [];
              setClientTypeListEditUser(tempClientList);
            }
            setShowEditUserClientType(true);
            if (editUserData?.client_type_index === 101) {
              editUserForm.setFieldValue("client_type", {
                client_type_index: editUserData?.client_type_index,
                client_type_name: editUserData?.client_type_name,
                label: editUserData?.client_type_label,
              });
            } else {
              editUserForm.setFieldValue("client_type", {
                client_type_index: editUserData?.client_type_index,
                client_type_name: editUserData?.client_type_name,
                label: editUserData?.client_type_label,
              });
              dispatch(
                getClientsSalesRep({
                  customer_id: editUserData?.client_list[0].customer_id,
                })
              ).then((result: any) => {
                if (result.payload?.salesmen_list?.length > 0) {
                  setSaleRepListEditUser(result.payload?.salesmen_list);
                } else {
                  setSaleRepListEditUser([]);
                }
                setShowEditUserSaleRep(true);
                editUserForm.setFieldValue("sale", {
                  CLIENT_ID: editUserData?.client_list[0].customer_id,
                  SALESMAN: editUserData?.salemen_name,
                  index: editUserData?.salemen_index,
                  sale_rep_update: null,
                  status: "Active",
                });
              });
            }
          }
        });
      } else if (
        editUserData?.role_index === 101 ||
        editUserData?.role_index === 102
      ) {
        setShowEditUserClients(true);
        editUserForm.setFieldValue("clients", editUserData?.client_list);
      }
    }
  };

  const handleEditUserChangeValidate = () => {
    let nameCheck = false;
    let roleCheck = false;

    if (
      editUserForm.values?.first_name === editUserData?.first_name &&
      editUserForm.values?.last_name === editUserData?.last_name
    ) {
      nameCheck = false;
      setNameChange(false);
    } else {
      nameCheck = true;
      setNameChange(true);
    }
    if (
      editUserForm.values?.user_role?.role_index === editUserData?.role_index
    ) {
      if (editUserData?.role_index === 103) {
        if (editUserData?.client_type_index === 100) {
          if (
            editUserData?.client_type_index ===
              editUserForm.values?.client_type?.client_type_index &&
            editUserData?.client_list[0].client_index ===
              editUserForm.values?.client.client_index &&
            editUserData?.salemen_index ===
              editUserForm.values?.sale_rep?.salemen_index
          ) {
            setRoleChange(false);
            roleCheck = false;
          } else {
            setRoleChange(true);
            roleCheck = true;
          }
        } else {
          if (
            editUserData?.client_type_index ===
              editUserForm.values?.client_type.client_type_index &&
            editUserData?.client_list[0].client_index ===
              editUserForm.values?.client.client_index
          ) {
            setRoleChange(false);
            roleCheck = false;
          } else {
            setRoleChange(true);
            roleCheck = true;
          }
        }
      } else if (
        editUserData?.role_index === 101 ||
        editUserData?.role_index === 102
      ) {
        let check = compareTwoObjectArray(
          editUserData?.client_list,
          editUserForm.values?.clients,
          "client_index"
        );
        if (check) {
          setRoleChange(false);
          roleCheck = false;
        } else {
          setRoleChange(true);
          roleCheck = true;
        }
      }
    } else {
      setRoleChange(true);
      roleCheck = true;
    }

    if (roleCheck || nameCheck) {
      return true;
    } else {
      setEditUserErrorMessage("No Changes have been made");
      return false;
    }
  };

  useEffect(() => {
    inititalEditUserSetup();
  }, [editUserData]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleCloseEditUserDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Edit User
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseEditUserDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addUserLoading} />
      <form onSubmit={editUserForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  defaultValue={
                    editUserData?.user_name ? editUserData?.user_name : ""
                  }
                  placeholder="Email"
                  label="Email"
                  id="email"
                  name="email"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={editUserForm.values.email}
                  disabled
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  defaultValue={
                    editUserData?.first_name ? editUserData?.first_name : ""
                  }
                  placeholder="First Name"
                  label="First Name"
                  id="first_name"
                  name="first_name"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={editUserForm.values.first_name}
                  error={
                    editUserForm.touched.first_name &&
                    Boolean(editUserForm.errors.first_name)
                  }
                  helperText={editUserForm.errors.first_name}
                  onChange={editUserForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  defaultValue={
                    editUserData?.last_name ? editUserData?.last_name : ""
                  }
                  placeholder="last Name"
                  label="Last Name"
                  id="last_name"
                  name="last_name"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={editUserForm.values.last_name}
                  error={
                    editUserForm.touched.last_name &&
                    Boolean(editUserForm.errors.last_name)
                  }
                  helperText={editUserForm.errors.last_name}
                  onChange={editUserForm.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <Autocomplete
                  id="role"
                  value={editUserForm.values.user_role}
                  defaultValue={
                    editUserData?.role
                      ? {
                          role: editUserData?.role,
                          role_index: editUserData?.role_index,
                          role_label: editUserData?.role_label,
                        }
                      : undefined
                  }
                  onChange={(_: SyntheticEvent, newValue: any | null) => {
                    setShowEditUserClientType(false);
                    setShowEditUserSaleRep(false);
                    setRoleErrorEditUser(false);
                    setRoleErrorMessageEditUser("");
                    setClientErrorEditUser(false);
                    setClientErrorMessageEditUser("");
                    setClientTypeErrorEditUser(false);
                    setClientTypeErrorMessageEditUser("");
                    setSaleRepErrorEditUser(false);
                    setSaleRepErrorMessageEditUser("");
                    setEditUserError(false);
                    setEditUserErrorMessage("");
                    if (newValue) {
                      if (newValue.role_index !== 100) {
                        if (newValue.role_index !== 103) {
                          editUserForm.setFieldValue("client", undefined);
                          setShowEditUserClients(true);
                          setShowEditUserClient(false);
                        } else {
                          editUserForm.setFieldValue("clients", undefined);
                          setShowEditUserClients(false);
                          setShowEditUserClient(true);
                        }
                      } else {
                        editUserForm.setFieldValue("clients", undefined);
                        editUserForm.setFieldValue("client", undefined);
                        setShowEditUserClients(false);
                        setShowEditUserClient(false);
                      }
                      editUserForm.setFieldValue("user_role", newValue);
                    } else {
                      editUserForm.setFieldValue("clients", undefined);
                      editUserForm.setFieldValue("client", undefined);
                      setShowEditUserClient(false);
                      setShowEditUserClients(false);
                      editUserForm.setFieldValue("user_role", undefined);
                    }
                  }}
                  options={allRoles ? allRoles : []}
                  getOptionLabel={(option: any) =>
                    option.role_label ? option.role_label : undefined
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.role_index === value?.role_index
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Role"
                      label={"Role"}
                      error={roleErrorEditUser}
                      helperText={roleErrorMessageEditUser}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} sx={{ padding: "5px" }}>
                {showEditUserClients && (
                  <Autocomplete
                    multiple
                    id="clients"
                    value={editUserForm.values.clients}
                    onChange={(_: SyntheticEvent, newValue: any | null) => {
                      setClientErrorEditUser(false);
                      setClientErrorMessageEditUser("");
                      setClientTypeErrorEditUser(false);
                      setClientTypeErrorMessageEditUser("");
                      setSaleRepErrorEditUser(false);
                      setSaleRepErrorMessageEditUser("");
                      setEditUserError(false);
                      setEditUserErrorMessage("");
                      if (newValue) {
                        editUserForm.setFieldValue("clients", newValue);
                      } else {
                        editUserForm.setFieldValue("clients", undefined);
                      }
                    }}
                    options={allClients ? allClients : []}
                    getOptionLabel={(option: any) =>
                      option.customer_name ? option.customer_name : undefined
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.client_index === value?.client_index
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Clients"
                        label="Clients"
                        error={clientErrorEditUser}
                        helperText={clientErrorMessageEditUser}
                      />
                    )}
                  />
                )}
                {showEditUserClient && (
                  <Autocomplete
                    id="client"
                    value={editUserForm.values.client}
                    onChange={(_: SyntheticEvent, newValue: any | null) => {
                      setShowEditUserSaleRep(false);
                      setClientErrorEditUser(false);
                      setClientErrorMessageEditUser("");
                      setClientTypeErrorEditUser(false);
                      setClientTypeErrorMessageEditUser("");
                      setSaleRepErrorEditUser(false);
                      setSaleRepErrorMessageEditUser("");
                      setEditUserError(false);
                      setEditUserErrorMessage("");
                      if (newValue) {
                        editUserForm.setFieldValue("client", newValue);
                        dispatch(
                          getClientsPrograms({
                            client_index: newValue.client_index,
                          })
                        ).then((result: any) => {
                          if (result.payload?.programs?.length > 0) {
                            let analytic_check = false;
                            result.payload.programs.forEach((program: any) => {
                              if (program.program_index === 100)
                                analytic_check = true;
                            });

                            if (analytic_check) {
                              setClientTypeListEditUser(clientType);
                            } else {
                              let tempClientList = clientType
                                ? clientType.filter(
                                    (client_type: any) =>
                                      client_type.client_type_index !== 100
                                  )
                                : [];
                              setClientTypeListEditUser(tempClientList);
                            }
                          }
                          setShowEditUserClientType(true);
                        });
                      } else {
                        setShowEditUserClientType(false);
                        editUserForm.setFieldValue("client", undefined);
                      }
                    }}
                    options={allClients ? allClients : []}
                    getOptionLabel={(option: any) =>
                      option.customer_name ? option.customer_name : undefined
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.client_index === value?.client_index
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Client"
                        label="Client"
                        error={clientErrorEditUser}
                        helperText={clientErrorMessageEditUser}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              {showEditUserClientType && (
                <Grid item xs={4} sx={{ padding: "5px" }}>
                  <Autocomplete
                    id="client_type"
                    value={editUserForm.values.client_type}
                    onChange={(_: SyntheticEvent, newValue: any | null) => {
                      setShowEditUserSaleRep(false);
                      setClientTypeErrorEditUser(false);
                      setClientTypeErrorMessageEditUser("");
                      setSaleRepErrorEditUser(false);
                      setSaleRepErrorMessageEditUser("");
                      setEditUserError(false);
                      setEditUserErrorMessage("");
                      if (newValue) {
                        if (newValue.client_type_index === 100) {
                          dispatch(
                            getClientsSalesRep({
                              customer_id:
                                editUserForm.values.client.customer_id,
                            })
                          ).then((result: any) => {
                            if (result.payload?.salesmen_list?.length > 0) {
                              setSaleRepListEditUser(
                                result.payload?.salesmen_list
                              );
                            } else {
                              setSaleRepListEditUser([]);
                            }
                            setShowEditUserSaleRep(true);
                          });
                        } else {
                          setShowEditUserSaleRep(false);
                        }
                        editUserForm.setFieldValue("client_type", newValue);
                      } else {
                        setShowEditUserSaleRep(false);
                        editUserForm.setFieldValue("client_type", undefined);
                      }
                    }}
                    options={clientTypeListEditUser}
                    isOptionEqualToValue={(option, value) =>
                      option?.client_type_index === value?.client_type_index
                    }
                    getOptionLabel={(option: any) =>
                      option.client_type_name
                        ? option.client_type_name
                        : undefined
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Client Type"
                        label="Client Type"
                        error={clientTypeErrorEditUser}
                        helperText={clientTypeErrorMessageEditUser}
                      />
                    )}
                  />
                </Grid>
              )}
              {showEditUserSaleRep && (
                <Grid item xs={8} sx={{ padding: "5px" }}>
                  <Autocomplete
                    id="sale_rep"
                    value={editUserForm.values.sale_rep}
                    onChange={(_: SyntheticEvent, newValue: string | null) => {
                      setSaleRepErrorEditUser(false);
                      setSaleRepErrorMessageEditUser("");
                      setEditUserError(false);
                      setEditUserErrorMessage("");
                      if (newValue) {
                        editUserForm.setFieldValue("sale_rep", newValue);
                      } else {
                        editUserForm.setFieldValue("sale_rep", undefined);
                      }
                    }}
                    options={saleRepListEditUser}
                    getOptionLabel={(option: any) =>
                      option.SALESMAN ? option.SALESMAN : undefined
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.SALESMAN === value?.SALESMAN
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Sale Rep"
                        label="Sale Rep"
                        error={saleRepErrorEditUser}
                        helperText={saleRepErrorMessageEditUser}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            {editUserError && (
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "red" }}>
                  {editUserErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid" }}>
          <Button
            variant="outlined"
            onClick={() => handleCloseEditUserDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditUserDialog;
