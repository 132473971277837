import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "3% 10% 3% 10%",
        alignItems: "center",
        backgroundColor: "#004680",
        fontSize: "16px",
        fontWeight: "600",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: "#fff",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}>
          Where should you focus?
        </Typography>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "white",
            color: "#004680",
            fontSize: "16px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          FIND OUT NOW
        </Button>
      </Grid>
      <Grid item sx={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}>
        <Typography sx={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}>
          @{year}{" "}
          <NavLink
            style={{
              textDecoration: "none",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "600",
            }}
            to={"/"}
          >
            Zintoro
          </NavLink>{" "}
          All Rights Reserved.
        </Typography>
      </Grid>
      <Grid item sx={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}>
        Created by{" "}
        <a
          style={{
            textDecoration: "none",
            color: "#fff",
            fontSize: "20px",
            fontWeight: "bold",
          }}
          href="https://www.winsbyinc.com/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Winsby</span> Inc.
        </a>
      </Grid>
    </Grid>
  );
};

export default Footer;
