import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import Analytics from "./analytics/analytics";
import Equipments from "./equipments/equipments";
import Learning from "./learning/learning";
import SaleRepView from "./saleRepView/saleRepView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getLastUpdated } from "../../store/thunks/portalManagementThunk";
import { logoutUser } from "../../store/thunks/authenticationThunk";
import { portalManagementReset } from "../../store/features/portalManagement/portalManagementSlice";
import { systemManagementReset } from "../../store/features/accountManagement/systemManagementSlice";
import { logout } from "../../store/features/authentication/autehnticationSlice";
import { useNavigate } from "react-router-dom";

const retentionRateClient = new QueryClient();
const learningClient = new QueryClient();

const AccountView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedProgram, selectedAccountType, selectedClient } =
    useAppSelector((state: any) => state.portalManagement);
  const { loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );

  useEffect(() => {
    dispatch(getLastUpdated({ payload: selectedClient?.customer_id })).then(
      (getLastUpdatedResult: any) => {
        if (getLastUpdatedResult?.error) {
          if (getLastUpdatedResult?.error?.message === "Rejected") {
            dispatch(logoutUser({ user_index: userInfo?.client_user_index }));
            dispatch(portalManagementReset());
            dispatch(systemManagementReset());
            dispatch(logout());
            navigate("/login");
          }
        }
      }
    );
  }, []);

  return (
    <Grid container sx={{}}>
      {selectedProgram?.program_index === 100 &&
        (selectedAccountType.client_type_index === 100 ? (
          <QueryClientProvider client={retentionRateClient}>
            <SaleRepView />
          </QueryClientProvider>
        ) : (
          <Analytics />
        ))}
      {selectedProgram?.program_index === 101 && (
        <QueryClientProvider client={learningClient}>
          <Learning />
        </QueryClientProvider>
      )}
      {selectedProgram?.program_index === 103 && <Equipments />}
    </Grid>
  );
};

export default AccountView;
