import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import why_zintoro from "./../../assets/why_zin_banner-scaled.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";

const WhyZintoro = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${why_zintoro})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Why Choose Zintoro
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 5% 10%",
        }}
      >
        <Typography variant="h4" sx={{ color: "#3aae4a", fontWeight: "bold" }}>
          We provide a blueprint for revenue growth
        </Typography>
        <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem sx={{ display: "list-item" }}>
            Accurate forecasting
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Actionable data you can actually use
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Strategies to improve your business metrics
          </ListItem>
        </List>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            Drawing on key business metrics, including customer retention and
            engagement, the number of new customers, and customer satisfaction,
            Zintoro offers a comprehensive examination of the existing and
            potential structure for your business. By identifying your strongest
            and weakest areas, our analysis evaluates key data and zeros in on
            the analytics that will help you build a stronger, more profitable
            operation.
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            What sets Zintoro apart from the competition is that our systems use
            artificial intelligence that adapts to constantly changing
            conditions to provide the best ways to achieve predictable growth.
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            More than just the numbers
          </Typography>
          <Typography>
            With Zintoro, we don’t just tell you your business metrics and send
            you on your way. We suggest tried and proven methodologies for
            revenue growth, tailored to your specific company, and give you a
            step by step outline to achieve it. By following the Zintoro
            recommendations, your company can improve on all of the most
            important of business metrics.
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography>
            We will also show you where your company ranks relative to similar
            businesses, so you can fully understand your potential.
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            If you want to understand your business metrics and know exactly
            what needs to be done for to consistently reach your revenue goals,{" "}
            <NavLink
              style={{
                textDecoration: "none",
                color: "#00467f",
              }}
              to={"/contactus"}
            >
              contact our team today.
            </NavLink>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhyZintoro;
