import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  attachProduct,
  getProducts,
} from "../../../store/thunks/systemManagementThunk";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { userInfo } from "os";
import Spinner from "../spinner/spinner";

interface AttachSubscriptionDialogProps {
  open: boolean;
  handleClose: any;
  attachSubscriptionData: any;
}

const AttachSubscriptionDialog = (props: AttachSubscriptionDialogProps) => {
  const { open, handleClose, attachSubscriptionData } = props;
  const dispatch = useAppDispatch();

  const { attachProductLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  console.log("attachSubscriptionData", attachSubscriptionData);
  const [productList, setProductList] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [attachProductError, setAttachProductError] = useState(false);
  const [attachProductErrorMessage, setAttachProductErrorMessage] =
    useState("");

  useEffect(() => {
    dispatch(getProducts({})).then((result: any) => {
      if (result.payload.products) {
        let table_product_list = result.payload.products.filter(
          (product: any) => {
            return product.status === "Active";
          }
        );
        setProductList(table_product_list);
      }
    });
  }, [attachSubscriptionData]);

  const handleCloseAttachSubscriptionDialog = (
    message: string,
    reason?: any
  ) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setSelectedProduct(null);
    handleClose(message);
  };

  const submitAttachSubscriptionDialog = () => {
    console.log("submitAttachSubscriptionDialog");
    const attachProductPayload = {
      client_user_index: attachSubscriptionData?.client_user_index,
      subscription: selectedProduct?.product_index,
      email: attachSubscriptionData?.user_name,
      first_name: attachSubscriptionData?.first_name,
      last_name: attachSubscriptionData?.last_name,
      stripe_customer_id: attachSubscriptionData?.stripe_customer_id,
    };
    console.log("attachProductPayload", attachProductPayload);
    dispatch(
      attachProduct({
        attachProductPayload: attachProductPayload,
      })
    ).then((attachProductResult: any) => {
      console.log("attachProductResult", attachProductResult);
      if (attachProductResult.payload.result === true) {
        handleCloseAttachSubscriptionDialog(
          attachProductResult.payload.message
        );
      } else {
        setAttachProductError(true);
        setAttachProductErrorMessage(attachProductResult.payload.message);
      }
    });
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={(event, reason) =>
        handleCloseAttachSubscriptionDialog("", reason)
      }
    >
      <Spinner loading={attachProductLoading} />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Attach Product
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseAttachSubscriptionDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2} item xs={12} sx={{ padding: "20px" }}>
            <Grid item xs={4} style={{}}>
              <TextField
                sx={{}}
                placeholder="Email"
                label="Email"
                id="email"
                name="email"
                variant="outlined"
                fullWidth
                value={attachSubscriptionData?.user_name}
                disabled
              />
            </Grid>
            <Grid item xs={8} style={{}}>
              <Autocomplete
                id="product"
                value={selectedProduct}
                onChange={(_: SyntheticEvent, newValue: string | null) =>
                  setSelectedProduct(newValue)
                }
                getOptionLabel={(option: any) =>
                  `${option?.description}: $${option?.price}`
                }
                options={productList}
                isOptionEqualToValue={(option: any, newValue: any) => {
                  return option?.product_index === newValue?.product_index;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product"
                    placeholder="Product"
                  />
                )}
              />
            </Grid>
          </Grid>
          {attachProductError && (
            <Grid sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ color: "red" }}>
                {attachProductErrorMessage}
              </Typography>
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid" }}>
        <Button
          variant="outlined"
          onClick={() => handleCloseAttachSubscriptionDialog("")}
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "red",
            color: "black",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "rgba(59, 147, 74, 1)",
            color: "white",
          }}
          onClick={() => submitAttachSubscriptionDialog()}
          disabled={!selectedProduct}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachSubscriptionDialog;
