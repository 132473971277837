import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { getBranchesDepartments } from "../../../store/thunks/portalManagementThunk";
import {
  portalManagementReset,
  setSelectedBranch,
  setSelectedDepartment,
} from "../../../store/features/portalManagement/portalManagementSlice";
import MiniSpinner from "../miniSpinner/miniSpinner";
import dayjs from "dayjs";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { logout } from "../../../store/features/authentication/autehnticationSlice";
import { useNavigate } from "react-router-dom";
import { systemManagementReset } from "../../../store/features/accountManagement/systemManagementSlice";

interface DashboardHeaderProps {
  title: string;
  headerType?: string;
  lastUpdated?: string;
}

const DashboardHeader = (props: DashboardHeaderProps) => {
  const dispatch = useAppDispatch();

  const handleBranchChange = (selection: any) => {
    dispatch(setSelectedBranch(selection));
  };
  const handleDepartmentChange = (selection: any) => {
    dispatch(setSelectedDepartment(selection));
  };
  const { title, headerType, lastUpdated } = props;
  const {
    selectedDepartment,
    selectedBranch,
    selectedClient,
    selectedAccountType,
    branches,
    departments,
    lastUpdateLoading,
  } = useAppSelector((state: any) => state.portalManagement);
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const [branchOptions, setBranchOption] = useState<any[]>([]);
  const [departmentOptions, setDepartmentOption] = useState<any[]>([]);

  useEffect(() => {
    const payload: any = {
      portal_account: selectedAccountType,
      customer_id: selectedClient.customer_id,
      user: userInfo.role,
    };
    dispatch(getBranchesDepartments({ payload: payload }));
  }, []);

  useEffect(() => {
    if (branches) {
      const overall = branches.filter(
        (element: any) => element.value === "Overall"
      );
      const not_overall = branches.filter(
        (element: any) => element.value !== "Overall"
      );

      setBranchOption([...overall, ...not_overall]);
    }
    if (departments) {
      const overall = departments.filter(
        (element: any) => element.value === "Overall"
      );
      const not_overall = departments.filter(
        (element: any) => element.value !== "Overall"
      );

      setDepartmentOption([...overall, ...not_overall]);
    }
  }, [branches, departments]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px 20px 10px 20px",
        width: "100%",
      }}
    >
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={8}>
          <Typography
            variant="h4"
            sx={{ color: "#004680", fontWeight: "bold" }}
          >
            {title?.toUpperCase()}
          </Typography>
          <Typography
            sx={{
              color: "#004680",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            Starting Date:{" "}
            {selectedClient?.create_date
              ? dayjs(selectedClient?.create_date).format("YYYY-MM-DD")
              : ""}
          </Typography>
          <Typography
            sx={{
              color: "#004680",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            Last Update : {lastUpdateLoading ? <MiniSpinner /> : lastUpdated}
          </Typography>
          <Typography
            sx={{
              color: "#004680",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            Currency : {selectedClient?.currency}
          </Typography>
        </Grid>
        {headerType === "analytic" && (
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <Autocomplete
                id="sales_rep"
                value={selectedBranch}
                onChange={(_: SyntheticEvent, newValue: string | null) =>
                  handleBranchChange(newValue)
                }
                options={branchOptions}
                isOptionEqualToValue={(option, newValue) => {
                  return option.id === newValue.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Branches"
                    placeholder="Branches"
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                id="sales_rep"
                value={selectedDepartment}
                onChange={(_: SyntheticEvent, newValue: string | null) =>
                  handleDepartmentChange(newValue)
                }
                options={departmentOptions}
                isOptionEqualToValue={(option, newValue) => {
                  return option.id === newValue.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departments"
                    placeholder="Departments"
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardHeader;
