import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authenticationReducer } from "./features/authentication/autehnticationSlice";
import { portalManagementReducer } from "./features/portalManagement/portalManagementSlice";
import { systemManagementReducer } from "./features/accountManagement/systemManagementSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { equipmentManagementReducer } from "./features/equipmentManagement/equipmentmanagementSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  portalManagement: portalManagementReducer,
  systemManagement: systemManagementReducer,
  equipmentManagement: equipmentManagementReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
