import { Grid, List, ListItem } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
const DetailsLinks = () => {
  return (
    <Grid>
      <List sx={{ padding: "40px 0px 0px 0px" }}>
        <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
          Key Metrics for Your Business
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Understand the metrics that matter
        </ListItem>
        <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
          Customer Retention
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/businessretention"}
          >
            How to Improve Customer Retention
          </NavLink>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/customerretention"}
          >
            Measuring Customer Retention – What a 91% Retention Rate Really
            Means
          </NavLink>
        </ListItem>
        <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
          Geographic Limits
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/geographicmarket"}
          >
            Do You Know Your Business’ Geographic Market?
          </NavLink>
        </ListItem>
        <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
          Revenue Goals
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/revenueanalyticsgoals"}
          >
            Achieve Your Revenue Goals: The First Step Is Understanding Where
            You Are Now
          </NavLink>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/revenuegoals"}
          >
            How to Reach Your Revenue Goals
          </NavLink>
        </ListItem>
        <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
          Target Industries
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/industryanalysis"}
          >
            What Is an Industry Analysis and Why Your Business Needs One
          </NavLink>
        </ListItem>
        <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
          Customer Purchases
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: "underline",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
                color: "grey",
              };
            }}
            to={"/ourblog/understandpurchases"}
          >
            Know the Types of Purchases Your Customers Are Making
          </NavLink>
        </ListItem>
      </List>
    </Grid>
  );
};
export default DetailsLinks;
