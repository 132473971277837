import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserManagement from "./userManagement/userManagement";
import ClientManagement from "./clientManagement/clientManagment";
import EmailManagement from "./email/emailManagment";
import SalesRepManagement from "./salesRepManagment/salesRepManagement";
import ProductManagement from "./productManagement/productManagement";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getClientPlan,
  getClientType,
  getClients,
  getPlans,
  getProducts,
  getPrograms,
  getRoles,
  getUsers,
} from "../../store/thunks/systemManagementThunk";
import { useNavigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { portalManagementReset } from "../../store/features/portalManagement/portalManagementSlice";

const userQueryClient = new QueryClient();
const clientQueryClient = new QueryClient();
const productQueryClient = new QueryClient();
const salesRepQueryClient = new QueryClient();

const SystemManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );

  const [selectedNavigation, setSelectedNavigation] = useState("user");
  useEffect(() => {
    if (loggedIn) {
      if (
        userInfo.role_index === 100 ||
        userInfo.role_index === 101 ||
        userInfo.role_index === 102
      ) {
        dispatch(getUsers({}));
        dispatch(getClients({}));
        dispatch(getRoles({}));
        dispatch(getProducts({}));
        dispatch(getPrograms({}));
        dispatch(getPlans({}));
        dispatch(getClientPlan({}));
        dispatch(getClientType({}));
        dispatch(portalManagementReset());
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/login");
    }
  }, []);

  const handleSystemNavigation = (navSelection: any) => {
    setSelectedNavigation(navSelection);
  };

  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          backgroundColor: "#4C4C4C",
          borderTop: "5px solid white",
          height: "40px",
          paddingLeft: "10px",
        }}
      >
        <Button
          onClick={() => {
            handleSystemNavigation("user");
          }}
          sx={{
            color:
              selectedNavigation === "user"
                ? "#38B54A !important"
                : "white !important",
            padding: "0px 20px 0px 20px !important",
          }}
        >
          User Management
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        {userInfo.role_index === 100 && (
          <Button
            onClick={() => {
              handleSystemNavigation("client");
            }}
            sx={{
              color:
                selectedNavigation === "client"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Client Management
          </Button>
        )}
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        {userInfo.role_index === 100 && (
          <Button
            onClick={() => {
              handleSystemNavigation("subscription");
            }}
            sx={{
              color:
                selectedNavigation === "subscription"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Product Management
          </Button>
        )}
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => {
            handleSystemNavigation("sales_rep");
          }}
          sx={{
            color:
              selectedNavigation === "sales_rep"
                ? "#38B54A !important"
                : "white !important",
            padding: "2px 20px 2px 20px !important",
          }}
        >
          Sales Rep Management
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderRight: "10px solid white",
          borderLeft: "10px solid white",
          backgroundColor: "rgba(176, 215, 255, 0.1)",
          height: "100vh",
        }}
      >
        {selectedNavigation === "user" && (
          <QueryClientProvider client={userQueryClient}>
            <UserManagement />
          </QueryClientProvider>
        )}
        {selectedNavigation === "client" && (
          <QueryClientProvider client={clientQueryClient}>
            <ClientManagement />
          </QueryClientProvider>
        )}
        {selectedNavigation === "subscription" && (
          <QueryClientProvider client={productQueryClient}>
            <ProductManagement />
          </QueryClientProvider>
        )}
        {selectedNavigation === "sales_rep" && (
          <QueryClientProvider client={salesRepQueryClient}>
            <SalesRepManagement />
          </QueryClientProvider>
        )}
      </Grid>
    </Grid>
  );
};

export default SystemManagement;
