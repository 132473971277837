export const backendURL =
  process.env.NODE_ENV !== "production" ? "http://127.0.0.1:5000" : "";

export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  },
};

export const Backend_Urls = {
  // auth urls
  login_url: "/api/login",
  data_url: "/api/client",
  register_url: "/api/register",
  logout_url: "/api/logout",
  reset_password_link_url: "/api/reset_password_link",
  reset_password_url: "/api/reset_password",
  refresh_token_url: "/api/refresh",

  // administration url
  admin_url: "/api/administrator",

  users_url: "/api/users",
  update_user_url: "/api/update_user",
  disable_user_url: "/api/delete_user",
  enable_user_url: "/api/enable_user",

  report_download_url: "/api/download_report",
  account_management: "/api/account_management",

  clients_url: "/api/clients",
  update_client_url: "/api/update_client",
  disable_client_url: "/api/delete_client",
  enable_client_url: "/api/enable_client",

  roles_url: "/api/roles",

  products_url: "/api/products",
  update_product_url: "/api/update_product",
  delete_product_url: "/api/delete_product",
  enable_product_url: "/api/enable_product",

  programs_url: "/api/programs",
  plans_url: "/api/plans",
  client_plan_url: "/api/client_plan",
  client_type_url: "/api/client_type",

  payment_config_url: "/api/payment_config",
  create_product_url: "/api/create_product",
  create_subscription_url: "/api/create_subscription",

  cancel_subscription_url: "/api/cancel_subscription",
  attach_subscription_url: "/api/attach_subscription",
  accept_payment_url: "/api/accept_payment",

  salemen_url: "/api/salemen",

  ///////////////////
  // Single endpoints
  ///////////////////

  // Get all branches and departments
  branches_and_departments: "/api/get_branches_and_departments",

  // Get last updated
  last_updated: "/api/get_last_updated",

  // Landing Page
  active_account_12mm: "/api/get_active_account_12mm",
  transaction_12mm: "/api/get_transaction_12mm",
  revenue_12mm: "/api/get_revenue_12mm",
  growth_score: "/api/get_growth_score",
  retention: "/api/get_retention",
  g_reviews: "/api/get_g_reviews",
  nps_score: "/api/get_nps_score",
  nps_survey: "/api/get_nps_survey",
  industries: "/api/get_industries",
  purchase: "/api/get_purchase",
  retention_leads: "/api/get_retention_leads",
  prospect_verified: "/api/get_prospect_verified",
  get_email_reports: "/api/get_email_reports",

  // Growth and Forecast
  growth_rate: "/api/get_growth_rate",
  growth_rate_detail: "/api/get_growth_rate_detail",
  growth_rate_benchmark: "/api/get_growth_rate_benchmark",
  forecast: "/api/get_forecast",
  forecast_detail: "/api/get_forecast_detail",
  atrisk: "/api/get_atrisk",
  atrisk_detail: "/api/get_atrisk_detail",
  wgs: "/api/get_wgs",
  wgs_detail: "/api/get_wgs_detail",

  // Retention
  ggd_rating: "/api/get_ggd_rating",
  ggd_rating_detail: "/api/get_ggd_rating_detail",
  net_promoter: "/api/get_net_promoter",
  customer_survey: "/api/get_customer_survey",
  customer_survey_detail: "/api/get_customer_survey_detail",
  retention_rate: "/api/get_retention_rate",

  // Transactions
  branch_transactions: "/api/get_branch_transactions",
  industry_by_revenue: "/api/get_industry_by_revenue",
  industry_by_revenue_detail: "/api/get_industry_by_revenue_detail",
  purchase_distribution: "/api/get_purchase_distribution",
  distribution_transaction_revenue: "/api/get_distribution_transaction_revenue",
  product_analysis_detail: "/api/get_product_analysis_detail",

  // Leads
  active_account: "/api/get_active_account",
  last_purchase_cust_leads: "/api/get_last_purchase_cust_leads",
  last_purchase_detail: "/api/get_last_purchase_detail",
  prospect_leads_reports: "/api/get_prospect_leads_reports",
  prospect_leads_reports_detail: "/api/get_prospect_leads_reports_detail",
  gain_and_lost_accounts: "/api/get_gain_and_lost_accounts",

  // Sales Rep
  rank_by_email: "/api/get_rank_by_email",
  rank_by_retention: "/api/get_rank_by_retention",
  rank_by_stable_account: "/api/get_rank_by_stable_account",
  rank_by_wgs: "/api/get_rank_by_wgs",
  rank_by_wgs_detail: "/api/get_rank_by_wgs_detail",

  // Marketing
  market_by_customer: "/api/get_market_by_customer",
  market_overview: "/api/get_market_overview",
  product_by_cust_count: "/api/get_product_by_cust_count",
  product_by_revenue: "/api/get_product_by_revenue",

  // Financial
  // balance_sheet: '/api/get_balance_sheet',
  // balance_sheet_2: '/api/get_balance_sheet_2',
  forecast_revenue_plan: "/api/forecast_revenue_plan",
  actual_margin_dict: "/api/margin_summary",
  // income_statement: '/api/get_income_statement',
  // income_statement_kpi: '/api/get_income_statement_kpi'

  employee_company: "/api/employee_company",
  employee_course: "/api/employee_course",
  employee_module: "/api/employee_module",
  employee_module_percentage: "/api/employee_module_percentage",
  employee_module_summary: "/api/employee_module_summary",
  employee_module_student_count: "/api/employee_module_student_count",
  employee_module_distribution: "/api/employee_module_distribution",
  employee_module_distribution_not: "/api/employee_module_distribution_not",
  employee_module_date_summary: "/api/employee_module_date_summary",

  programs: "/api/programs",
  client_programs: "/api/client_programs",
  client_salesmen: "/api/client_salesmen",

  client_salesmen_lead: "/api/client_salesmen_lead",

  client_salesmen_data: "/api/client_salesmen_data",

  email_reports: "/api/email_reports",
  user_email_reports: "/api/user_email_reports",
  client_email_reports: "/api/client_email_reports",
  all_client_salesmen: "/api/all_client_salesmen",
  deactivate_sales_rep: "/api/deactivate_sales_rep",
  activate_sales_rep: "/api/activate_sales_rep",

  payment_intent: "/api/payment_intent",
  cancel_payment_intent: "/api/cancel_payment_intent",
};

export const Product_Type_Option = [
  {
    label: "One Time",
    value: "One Time",
  },
  {
    label: "Subscription",
    value: "Subscription",
  },
];
