import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utils/authMidleware";
import { backendURL, config, Backend_Urls } from "../../utils/constants";

const loginUser = createAsyncThunk(
  "authentication/login",
  async (
    { username, password }: { username: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.login_url}`,
        { username, password },
        config
      );

      // store user's token in local storage
      localStorage.setItem("token", data.access_token);
      localStorage.setItem("refreshToken", data.refresh_token);

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const verifyEmail = createAsyncThunk(
  "authentication/verifyEmail",
  async (
    { verificationToken }: { verificationToken: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}/api/v1/auth/verify-email`,
        {
          verificationToken,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const resetPassword = createAsyncThunk(
  "authentication/resetpassword",
  async (
    { password, token }: { password: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${Backend_Urls.reset_password_url}/${token}`,
        {
          password: password,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const resetPasswordLink = createAsyncThunk(
  "authentication/resetpasswordlink",
  async ({ username }: { username: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${Backend_Urls.reset_password_link_url}`,
        {
          username,
        },
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const resetPasswordLinkVerification = createAsyncThunk(
  "authentication/resetpasswordlinkverification",
  async ({ token }: { token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${Backend_Urls.reset_password_url}/${token}`,
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const logoutUser = createAsyncThunk(
  "authentication/logout",
  async ({ user_index }: { user_index: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.logout_url}`,
        { user_index },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  loginUser,
  logoutUser,
  resetPassword,
  resetPasswordLink,
  resetPasswordLinkVerification,
  verifyEmail,
};
