import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { UserInfo } from "../../../../types/formTypes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addUser } from "../../../../store/thunks/systemManagementThunk";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";

interface EnableSaleRepDialogProps {
  open: boolean;
  handleClose: (message: string) => void;
  selectedSalesRepData: any;
}

const EnableSaleRepDialog = (props: EnableSaleRepDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleClose, selectedSalesRepData } = props;

  const { addUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const handleCloseEnableSaleRepDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    enableSaleRepForm.resetForm();
    handleClose(message);
  };

  let enableSaleRepInitialValues: Partial<UserInfo> = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const [enableSaleRepError, setEnableSaleRepError] = useState(false);
  const [enableSaleRepErrorMessage, setEnableSaleRepErrorMessage] =
    useState("");

  const validateEnableSaleRep = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    first_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is Required"),
    last_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is Required"),
  });

  const enableSaleRepForm = useFormik({
    initialValues: enableSaleRepInitialValues,
    validationSchema: validateEnableSaleRep,
    enableReinitialize: true,
    onSubmit: (values: Partial<UserInfo>) => {
      let enableSaleRepPayload: any = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        client: selectedSalesRepData?.client_index,
        client_type: 100,
        role: 103,
        sale_rep: selectedSalesRepData?.SALESMAN,
      };

      dispatch(addUser({ createUserPayload: enableSaleRepPayload })).then(
        (result: any) => {
          if (result.payload.result === true) {
            handleCloseEnableSaleRepDialog(result.payload.message);
          } else {
            setEnableSaleRepError(true);
            setEnableSaleRepErrorMessage(result.payload.message);
          }
        }
      );
    },
  });

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => handleCloseEnableSaleRepDialog("", reason)}
      aria-labelledby="enable-sales-rep-dialog"
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Enable Sales Rep
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseEnableSaleRepDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addUserLoading} />
      <form onSubmit={enableSaleRepForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  defaultValue={
                    selectedSalesRepData?.SALESMAN
                      ? selectedSalesRepData?.SALESMAN
                      : ""
                  }
                  placeholder="Sale Rep Name"
                  label="Sale Rep Name"
                  id="email"
                  name="email"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={selectedSalesRepData?.SALESMAN}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Email"
                  label="Email"
                  id="email"
                  name="email"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={enableSaleRepForm.values.email}
                  error={
                    enableSaleRepForm.touched.email &&
                    Boolean(enableSaleRepForm.errors.email)
                  }
                  helperText={enableSaleRepForm.errors.email}
                  onChange={enableSaleRepForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  placeholder="First Name"
                  label="First Name"
                  id="first_name"
                  name="first_name"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={enableSaleRepForm.values.first_name}
                  error={
                    enableSaleRepForm.touched.first_name &&
                    Boolean(enableSaleRepForm.errors.first_name)
                  }
                  helperText={enableSaleRepForm.errors.first_name}
                  onChange={enableSaleRepForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="last Name"
                  label="Last Name"
                  id="last_name"
                  name="last_name"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={enableSaleRepForm.values.last_name}
                  error={
                    enableSaleRepForm.touched.last_name &&
                    Boolean(enableSaleRepForm.errors.last_name)
                  }
                  helperText={
                    enableSaleRepForm.errors.last_name
                      ? enableSaleRepForm.errors.last_name
                      : ""
                  }
                  onChange={enableSaleRepForm.handleChange}
                />
              </Grid>
            </Grid>
            {enableSaleRepError && (
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "red" }}>
                  {enableSaleRepErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => handleCloseEnableSaleRepDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
            disabled={
              Boolean(enableSaleRepForm.errors.email) ||
              Boolean(enableSaleRepForm.errors.first_name) ||
              Boolean(enableSaleRepForm.errors.last_name)
                ? true
                : false
            }
          >
            {addUserLoading ? "Enabling..." : "Enable"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EnableSaleRepDialog;
