import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import AccountView from "../../components/accountView/accountView";
import PortalManagement from "../../components/portalManagement/portalManagement";
import SaleRepView from "../../components/accountView/saleRepView/saleRepView";
import {
  setSelectedClientOther,
  setSelectedSaleRep,
} from "../../store/features/portalManagement/portalManagementSlice";
import { useNavigate } from "react-router-dom";
const Account = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mainView } = useAppSelector((state: any) => state.portalManagement);
  const { userInfo, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  const [saleRepView, setSaleRepView] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      if (userInfo.role_index === 103) {
        if (userInfo.client_type_name === "Manager") {
          dispatch(
            setSelectedClientOther({
              client_index: userInfo.client_index,
              customer_id: userInfo.client_id,
              customer_name: userInfo.client_name,
            })
          );
        } else {
          dispatch(
            setSelectedClientOther({
              client_index: userInfo.client_index,
              customer_id: userInfo.client_id,
              customer_name: userInfo.client_name,
            })
          );
          dispatch(
            setSelectedSaleRep({
              SALESMAN: userInfo.sale_rep,
            })
          );
          setSaleRepView(true);
        }
      }
    } else {
      navigate("/login");
    }
  }, [userInfo, loggedIn]);
  return (
    <Grid container sx={{}}>
      {saleRepView ? (
        <SaleRepView />
      ) : mainView ? (
        <PortalManagement />
      ) : (
        <AccountView />
      )}
    </Grid>
  );
};

export default Account;
