import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_clients from "./../../assets/our_client_banner.jpg";
import Bergquist from "./../../assets/our_client_img2.png";
import Andersons from "./../../assets/anderson.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const OurClients = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_clients})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Clients
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Share Their
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Thoughts on
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Zintoro
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2% 10% 5% 10%",
        }}
      >
        <Typography>
          We work with all types of b2b companies, including manufacturers,
          distributors, and service providers. The analytics and business
          metrics reporting that we deliver allow companies to optimize
          operations, improve marketing strategies, and boost overall
          performance. Some of our clients shared how Zintoro b2b analytics have
          helped them better understand their customers and achieve a higher
          level of success.
        </Typography>
        <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
          <Grid container item xs={6}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  backgroundImage: `url(${Bergquist})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  color: "#fff",
                  backgroundColor: "#fff",
                  margin: "0px 0px 20px 0px",
                  height: "120px",
                }}
              ></Grid>
            </Grid>
            <Grid container sx={{}}>
              <Typography
                variant="h4"
                sx={{ color: "#3aae4a", fontWeight: "bold" }}
              >
                Bergquist
              </Typography>
            </Grid>
            <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography>
                Bergquist is the largest independent propane equipment
                distributor in the U.S. They offer a complete line of propane,
                high pressure valves, and anhydrous ammonia parts, equipment,
                and supplies from a wide variety of leading manufacturers.
                Zintoro b2b analytics uncovered key trends and formation about
                their customers that they didn’t know before and helped increase
                sales.
              </Typography>
            </Grid>
            <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
              &gt;{" "}
              <NavLink
                style={{
                  textDecoration: "none",
                  // color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                to={"/ourclients/bergquist"}
              >
                Read The Interview
              </NavLink>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{}}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  height: "120px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${Andersons})`,
                    color: "#fff",
                    backgroundColor: "#fff",
                    height: "60px",
                    width: "170px",
                  }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{}}>
              <Typography
                variant="h4"
                sx={{
                  color: "#3aae4a",
                  fontWeight: "bold",
                  margin: "20px 0px 0px 0px",
                }}
              >
                Andersons’ Sales & Service
              </Typography>
            </Grid>
            <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography>
                Andersons’ Sales & Service provides high quality commercial and
                residential lawn mowers, a wide range of other outdoor
                equipment, along with parts and expert service. They operate
                locations in Indiana, Kentucky, and Texas and use Zintoro
                business metrics to improve their customer retention and grow
                their business across the U.S.
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                margin: "45px 0px 10px 0px",
              }}
            >
              &gt;{" "}
              <NavLink
                style={{
                  textDecoration: "none",
                  // color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                to={"/ourclients/andersons"}
              >
                Read The Interview
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurClients;
