import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import phone from "./../../assets/phone.png";
import email from "./../../assets/email.png";
import { contactUsInfo } from "../../types/formTypes";
import { useFormik } from "formik";
import address from "./../../assets/location.png";
// import address_iframe from "./../../assets/";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const ContactUs = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  let contactUsInitialValues: contactUsInfo = {
    full_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    title: "",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validateContactUs = Yup.object({
    full_name: Yup.string().required("Fist Name is Required"),
    company_name: Yup.string().required("Last name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number isRequired"),
    title: Yup.string().required("Title is Required"),
  });

  const contactUsForm = useFormik({
    initialValues: contactUsInitialValues,
    validationSchema: validateContactUs,
    onSubmit: (values: contactUsInfo) => {
      console.log("contactUsForm values", values);
      window.location.href = "https://calendly.com/csclegg";
    },
  });
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
          backgroundColor: "#3b934a",
          padding: "2% 10% 5% 10%",
        }}
      >
        <Grid container sx={{}}>
          <Grid container item xs={5} sx={{}} gap={4}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 600,
              }}
            >
              Exceed Your Revenue
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 600,
              }}
            >
              Goals by Monitoring
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 600,
              }}
            >
              the Metrics that Matter
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${phone})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                color: "#fff",
                justifyContent: "center",
              }}
            ></Grid>

            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", margin: "10px 0px 0px 0px " }}
            >
              <a
                href="tel:847-772-4610"
                style={{ textDecoration: "none", color: "white" }}
              >
                847-772-4610
              </a>
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${email})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                alignItems: "center",
                color: "#fff",
                justifyContent: "center",
              }}
            ></Grid>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", margin: "10px 0px 0px 0px " }}
            >
              <a
                href="mailto:csclegg@zintoro.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                csclegg@zintoro.com
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid item xs={7}>
          <Typography
            variant="h6"
            sx={{
              color: "#00467f",
              fontWeight: "bold",
              margin: "20px 0px 0px 0px ",
            }}
          >
            Complete the form below, and include any questions or requests.
          </Typography>
          <form onSubmit={contactUsForm.handleSubmit}>
            <Grid container gap={2} sx={{ padding: "20px" }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  sx={{}}
                  placeholder="Full Name"
                  label="Full Name"
                  id="full_name"
                  name="full_name"
                  variant="filled"
                  inputProps={{}}
                  fullWidth
                  value={contactUsForm.values.full_name}
                  error={
                    contactUsForm.touched.full_name &&
                    Boolean(contactUsForm.errors.full_name)
                  }
                  helperText={contactUsForm.errors.full_name}
                  onChange={contactUsForm.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  sx={{}}
                  placeholder="Title"
                  label="Title"
                  id="title"
                  name="title"
                  variant="filled"
                  inputProps={{}}
                  fullWidth
                  value={contactUsForm.values.title}
                  error={
                    contactUsForm.touched.title &&
                    Boolean(contactUsForm.errors.title)
                  }
                  helperText={contactUsForm.errors.title}
                  onChange={contactUsForm.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  sx={{}}
                  placeholder="Email"
                  label="Email"
                  id="email"
                  name="email"
                  variant="filled"
                  inputProps={{}}
                  fullWidth
                  value={contactUsForm.values.email}
                  error={
                    contactUsForm.touched.email &&
                    Boolean(contactUsForm.errors.email)
                  }
                  helperText={contactUsForm.errors.email}
                  onChange={contactUsForm.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  sx={{}}
                  placeholder="Company Name"
                  label="Company Name"
                  id="company_name"
                  name="company_name"
                  variant="filled"
                  inputProps={{}}
                  fullWidth
                  value={contactUsForm.values.company_name}
                  error={
                    contactUsForm.touched.company_name &&
                    Boolean(contactUsForm.errors.company_name)
                  }
                  helperText={contactUsForm.errors.company_name}
                  onChange={contactUsForm.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  sx={{}}
                  placeholder="Phone Number"
                  label="Phone Number"
                  id="phone_number"
                  name="phone_number"
                  variant="filled"
                  inputProps={{}}
                  fullWidth
                  value={contactUsForm.values.phone_number}
                  error={
                    contactUsForm.touched.phone_number &&
                    Boolean(contactUsForm.errors.phone_number)
                  }
                  helperText={contactUsForm.errors.phone_number}
                  onChange={contactUsForm.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", backgroundColor: "#004680" }}
                  onClick={() => {
                    console.log("what is going on", contactUsForm.errors);

                    // contactUsForm.submitForm;
                  }}
                  type="submit"
                >
                  {/* <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://calendly.com/csclegg"
                  > */}
                  Reserve a Time for an Introductory Call
                  {/* </a> */}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={5} sx={{ padding: "5% 0% 0% 0%" }}>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "34px",
            }}
          >
            <Grid
              container
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${address})`,
                backgroundRepeat: "no-repeat",
                color: "#fff",
                height: "34px",
                width: "25px",
                backgroundPosition: "center",
              }}
            ></Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                margin: "20px 0px 20px 0px",
              }}
            >
              105 W. Madison St., Suite 2000 Chicago, IL 60602
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <iframe
              title="location"
              style={{ width: "100%", height: "100%" }}
              src="embed.html"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
