import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_partners from "./../../assets/partner_banner.jpg";
import winsby from "./../../assets/partner_logo1.png";
import ronlee from "./../../assets/partner_logo2.png";
import reflective from "./../../assets/partner_logo3.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
const OurPartners = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_partners})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "2% 10% 5% 10%" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Partners
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ padding: "2% 10% 5% 10%" }}>
        <Grid container>
          <Grid container item xs={8} style={{}}>
            <Typography
              variant="h5"
              sx={{ color: "#3aae4a", fontWeight: "bold" }}
            >
              Together, we deliver comprehensive solutions for our clients
            </Typography>
            <Grid container sx={{ margin: "10px 0px 10px 0px" }}>
              <Typography>
                We work with our strategic partners to provide the key metrics
                and analytics you need to make the best decisions possible for
                your company. Then, we offer the tools and strategies to improve
                your numbers and consistently grow your business. Here are our
                three primary partners:
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "20px 0px 10px 0px" }}>
          <Grid container item xs={9}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Winsby Inc.
            </Typography>
            <Typography>
              Winsby has been working with manufacturers, distributors, service
              providers, and retail businesses to help them grow their
              businesses for over 15 years. They have tested, developed, and
              rolled out marketing initiatives for hundreds of companies that
              continue to thrive and grow. Monthly emails, calling customers and
              prospects, customer satisfaction surveys, websites, social media
              posts, website development, real time performance dashboards, and
              dealer customer transaction tracking are just some of their highly
              effective and affordable marketing services.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              item
              xs={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${winsby})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // backgroundPosition: "center",
                height: "50px",
                width: "50px",
              }}
              onClick={() => {
                const url = "https://www.winsbyinc.com/";
                window.open(url, "_blank");
              }}
            ></Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "20px 0px 10px 0px" }}>
          <Grid
            container
            item
            xs={9}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Ron Slee
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Learning without Scars
            </Typography>
            <Typography>
              Founded by Ron Slee, Learning Without Scars, an IACET
              (International Accreditors for Continuing Education and Training)
              Approved Education Provider, has developed specialized, job
              function-based online training classes, seminars, and webinars.
              These courses are designed for manufacturers, dealers,
              associations, and specialized service business in the construction
              equipment, light industrial, on-highway, engine, and agricultural
              industries.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              item
              xs={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${ronlee})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "90px",
                width: "50px",
              }}
              onClick={() => {
                const url = "https://learningwithoutscars.org/";
                window.open(url, "_blank");
              }}
            ></Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "20px 0px 10px 0px" }}>
          <Grid
            container
            item
            xs={9}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              John G. Carlson
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Reflective Performance Inc.
            </Typography>
            <Typography>
              Reflective Performance Inc. is a data and analytics company that
              provides a human centered approach to increase productivity and
              corporate profitability. Informed by extensive experience in
              corporate turnarounds and operational improvements, Reflective
              Performance helps organizations enable a more stable and highly
              productive workforce — regardless of industry, products, or
              technologies — by applying the cognitive science of “executive
              function” (EF) to transform performance.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              item
              xs={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${reflective})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "60px",
              }}
              onClick={() => {
                const url = "https://reflectiveperformanceinc.com/";
                window.open(url, "_blank");
              }}
            ></Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "20px 0px 10px 0px" }}>
          <NavLink
            style={({ isActive, isPending, isTransitioning }) => {
              return {
                textDecoration: isActive ? "underline" : "none",
                textUnderlineOffset: 5,
                fontSize: "16px",
                fontWeight: "600",
              };
            }}
            to={"/contactus"}
          >
            Work with Zintoro and Our Partners
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurPartners;
