import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/industry.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useAppSelector } from "../../hooks/hooks";
const IndustryAnalysis = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>
          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              What Is an Industry Analysis and Why Your Business Needs One
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              As a business, you want to reach and sell to as many customers as
              possible. The problem is that it takes resources to connect with
              prospects and potential customers. Your company only has a limited
              amount of those resources, which means you have to make decisions
              about who to target with your marketing and sales efforts, because
              you can’t target everyone.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              The key is to go after those people who are most likely to work
              with you, and who have the potential to produce the most value for
              your business. That’s where an industry analysis comes in.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              What is an industry analysis? The basics
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              At Zintoro, we conduct your industry analysis by looking at
              Standard Industrial Classification (SIC) codes that are
              represented in your company’s existing customer list. SIC codes
              essentially tell you which industry a particular company is in. By
              analyzing the customer count, sales volume, revenue, number of
              potential customers, and other metrics for every industry SIC code
              in your list, you can understand the market potential for each
              segment of your customer base.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Knowing which segments of your existing and potential customers
              produce the most value for your company will help you recognize
              which industries have the best revenue potential. Armed with this
              information, you’ll understand how much value different customers
              and segments of customers can produce for your company, which can
              inform your decisions on which new prospects to target. You can
              then focus your time and resources on increasing your market share
              in the industries with the most potential.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              How to take advantage of an industry analysis
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              In order to gain the most value from an industry analysis, you
              have to understand which industries are represented in your
              current customer list and how valuable each of those industries is
              to your company. This analysis will determine your marketing
              strategy and help you place your resources where they are most
              likely to benefit your business.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Once you know which industries you should be targeting, you can
              then tailor your sales and marketing messaging to match those
              industries. For example, if you sell excavators and find out that
              the majority of your current customers exclusively handle
              demolition jobs, then you can target more demolition companies by
              highlighting the aspects of your products that are most useful to
              them.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              By focusing on your company’s target industries, you’ll waste
              fewer resources and be much more successful in growing your
              customer base and increasing sales.
            </Typography>

            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              Want to better understand your customer base and achieve stronger
              growth?
            </Typography>
            <Typography sx={{}}>
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/contactus"}
              >
                Contact Zintoro for a market analysis today!
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default IndustryAnalysis;
