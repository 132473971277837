import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { disableUser } from "../../../../store/thunks/systemManagementThunk";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
interface DisableUserDialogProps {
  open: any;
  handleDisableClose: any;
  disableUserData: any;
}

const DisableUserDialog = (props: DisableUserDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleDisableClose, disableUserData } = props;

  const { addUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const [editDisableError, setEditDisableError] = useState(false);
  const [editDisableErrorMessage, setEditDisableErrorMessage] = useState("");

  const handleDisableUserClose = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    handleDisableClose(message);
  };

  const submitDisableUser = () => {
    dispatch(
      disableUser({ client_user_index: disableUserData?.client_user_index })
    ).then((result: any) => {
      if (result.payload.result === true) {
        handleDisableUserClose(result.payload.message);
      } else {
        setEditDisableError(true);
        setEditDisableErrorMessage(result.payload.message);
      }
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleDisableUserClose("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Disable User
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleDisableUserClose("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addUserLoading} />
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Are you sure that you want to disable email:
            <span style={{ fontWeight: "bold" }}>
              {disableUserData?.user_name}
            </span>
            , Full Name:{" "}
            <span style={{ fontWeight: "bold" }}>
              {disableUserData?.first_name}, {disableUserData?.last_name}
            </span>
          </Grid>
          {editDisableError && (
            <Grid
              container
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ color: "red" }}>
                {editDisableErrorMessage}
              </Typography>
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid" }}>
        <Button
          variant="outlined"
          onClick={() => handleDisableUserClose("")}
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "red",
            color: "black",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => submitDisableUser()}
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "rgba(59, 147, 74, 1)",
            color: "white",
          }}
        >
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableUserDialog;
