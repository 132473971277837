import { Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import our_blog from "./../../assets/blog_banner.jpg";
import our_clients_1 from "./../../assets/customer.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import DetailsLinks from "../../components/reusableComponents/detailsLinks/detailsLinks";
import { useAppSelector } from "../../hooks/hooks";
const BusinessRetention = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((state: any) => state.authentication);
  useEffect(() => {
    if (loggedIn) navigate("/dashboard");
  }, []);
  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${our_blog})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5% 10% 5% 10%",
          color: "#fff",
          height: "500px",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ margin: "10px 0px 10px 0px" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Our Blog
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "2% 10% 5% 10%" }}>
        <Grid container item xs={7} style={{ padding: "0px 20px 0px 0px" }}>
          <Grid
            item
            xs={11}
            sx={{
              backgroundImage: `url(${our_clients_1})`,
              backgroundRepeat: "no-repeat",
              color: "#fff",
              backgroundColor: "#fff",
              height: "300px",
            }}
          ></Grid>
          <Grid container sx={{}}>
            <Typography
              variant="h5"
              sx={{
                color: "#3aae4a",
                fontWeight: "bold",
                margin: "20px 0px 0px 0px ",
              }}
            >
              How to Improve Customer Retention
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Customer retention is crucial for the success of every company. It
              describes the percentage of customers who purchased within the
              last 12 months who also purchased within the prior 12 months—13 to
              24 months ago. Poor customer retention means that you aren’t
              keeping customers over the long term and people are not committed
              to working with you. The result is that you have to devote
              resources continually toward acquiring new customers, taking up
              valuable time and money.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Maintaining strong customer retention is important for two primary
              reasons. The first is that it’s significantly more expensive to
              sell to a new customer than to an existing one. The second is that
              the longer a customer works with you, the more they buy from you
              and the more valuable they become to you.
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              The question is, how can you improve your customer retention?
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              Understand why customers leave you
            </Typography>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              The primary reasons that customer will leave you are these:
            </Typography>
            <List sx={{ listStyleType: "disc", padding: "0px 0px 0px 20px" }}>
              <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
                You’re mismanaging their expectations and not keeping them
                informed on the status of their orders
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                There is a change in contacts for either the customer or for you
              </ListItem>
              <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
                Your employees aren’t adequately trained or knowledgeable about
                your products and services or they lack the information system
                support and tools required to be responsive.
              </ListItem>
            </List>
            <Typography sx={{ margin: "10px 0px 0px 0px " }}>
              Despite what you may think, price is not at the top of the list
              when it comes to reasons that customers leave your company to
              purchase from a competitor. Once you understand why people may
              stop working with you, you can take the steps to prevent them from
              leaving to buy from competitors.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "20px 0px 0px 0px" }}
            >
              How Zintoro helps you retain more customers
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <span style={{ fontWeight: "bold" }}>
                Provide a personalized customer experience
              </span>{" "}
              – Our AI system tracks each of your customers to identify their
              next purchase, what industry and market they are in, and whether
              they are at risk of being lost. Armed with that information, your
              sales team can personalize customer interactions and tailor offers
              and recommendations to meet their specific needs. Our partner
              Winsby will keep your master lists up to date with the correct
              contacts, phone numbers, and email addresses.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <span style={{ fontWeight: "bold" }}>
                Develop strong relationships through consistent communication
              </span>{" "}
              – Zintoro helps you contact and communicate with customers in
              several ways. First, you can use purchase history data to identify
              people who have not purchased in their usual time period, then
              reach out to ask about their needs. Second, you can distribute
              highly effective emails through Winsby. Customers who receive
              Winsby emails typically purchase two to three times more often
              than those who don’t. Third, Zintoro works with most CRM systems
              to integrate analytics data with your sales and marketing data,
              helping streamline customer communication.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <span style={{ fontWeight: "bold" }}>
                Use customer feedback to take action
              </span>{" "}
              – Implementing Winsby’s customer satisfaction surveys is a great
              first step, but you have to actually act on the information and
              feedback you receive. Utilize the insights from the surveys to
              improve your sales process, products, and other aspects of your
              business before at risk customers leave you.
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              <span style={{ fontWeight: "bold" }}>
                Recognize the signs of at risk customers
              </span>{" "}
              – Zintoro tracks the frequency, consistency, and types of
              purchases, so you can know who your at risk customers are. Your
              sales team can then use that information and reach out to those
              customers, ask about their needs, and even provide a special offer
              or other incentive to encourage them to stay with you
            </Typography>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              Recognize the signs of at risk customers
            </Typography>
            <Typography sx={{ margin: "20px 0px 0px 0px " }}>
              Increasing your retention rate is an ongoing process that should
              be started as soon as possible. Building lasting relationships
              with customers is crucial for the long term viability of your
              company, because it makes them more valuable to you and it takes
              fewer resources to sell to them. Zintoro gives you the information
              and tools required to retain customers and boost your sales.
            </Typography>
            <Typography
              sx={{ margin: "20px 0px 0px 0px ", fontWeight: "bold" }}
            >
              <NavLink
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    hover: { textDecoration: "underline" },
                    textDecoration: isActive ? "underline" : "none",
                    textUnderlineOffset: 5,
                    fontSize: "16px",
                    fontWeight: "600",
                  };
                }}
                to={"/contactus"}
              >
                Schedule a Zintoro demo
              </NavLink>{" "}
              to find out how to boost your customer retention, track and
              accurately forecast business performance, and to determine the ROI
              for your marketing and customer satisfaction efforts.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5} style={{}}>
          <DetailsLinks />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusinessRetention;
