import React from "react";
import { Paper } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MaterialReactTable } from "material-react-table";

interface TableTemplateProps {
  tableData: any;
}

const TableTemplate = (props: TableTemplateProps) => {
  const { tableData } = props;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable table={tableData} />
      </LocalizationProvider>
    </Paper>
  );
};

export default TableTemplate;
